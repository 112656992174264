import {useState, useMemo, useEffect, useCallback} from 'react';
import {useLocation, useNavigate} from 'react-router';
import {useQuery} from 'react-query';
import dayjs from 'dayjs';
import {useStoreContext} from '@store/store-context';
import {useCompanyCheckinsHook} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.hooks';
import {useCheckinsCalendar} from '@hooks/checkins-calendar';
import {
  getWeekOfYear,
  getMonthFromWeek,
  getWeekRangeWorkDays,
} from '@utils/date';
import {emojiList} from '@ui/assets/emojis';
import {useAnalyticsQuestionHook} from '@pages/dashboard/reporting/reporting-pages/admin-analytics/analytics-pages/analytics-questions/question-hook';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {useQueries} from 'react-query';

export function useCheckinsDirectReports() {
  const {
    fetchListOfCheckins,
    userFilter,
    updateUserFilter,
    clearSelection,
    fetchMonthlyListOfCheckins,
    loading,
    setClearSelection,
    clearPagination,
  } = useCompanyCheckinsHook(true);

  const {
    checkinsStore: {
      usersCheckins,
      checkinsDates,
      usersCheckinsPaginator,
      monthCheckinsFilter,
      configuration,
      setCheckinsDate,
      calendarWeeksDirectReports,
    },
    authStore: {setCalendarView},
  } = useStoreContext();

  const location = useLocation();
  const navigate = useNavigate();

  const [updateDate, setUpdateDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>('');
  const [isQuestionSelectDisabled, setIsQuestionSelect] = useState(true);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [showQuestionSelectApply, setShowQuestionSelectApply] = useState(true);

  const calendarData = useCheckinsCalendar(
    checkinsDates?.year,
    checkinsDates?.month,
    checkinsDates?.date,
  );

  const moods = useMemo(
    () => [
      {emoji: emojiList.drained, text: 'Drained'},
      {emoji: emojiList.sad, text: 'Sad'},
      {emoji: emojiList.meh, text: 'Meh!'},
      {emoji: emojiList.happy, text: 'Happy'},
      {emoji: emojiList.elated, text: 'Elated'},
    ],
    [],
  );

  const months = useMemo(
    () => [
      'Jan.',
      'Feb.',
      'Mar.',
      'Apr.',
      'May',
      'Jun',
      'Jul.',
      'Aug.',
      'Sep.',
      'Oct.',
      'Nov.',
      'Dec.',
    ],
    [],
  );

  const week = Object.fromEntries(new URLSearchParams(location.search as any));

  const filters = useMemo(
    () => ({
      year: checkinsDates?.year,
      weekOfYear:
        monthCheckinsFilter || week?.week || checkinsDates?.weekOfYear,
      user:
        userFilter?.members.length > 0
          ? userFilter?.members
              .map((user: {value: string}) => user.value)
              .join(',')
          : undefined,
      manager:
        userFilter?.members.length === 0 &&
        (userFilter.selectedTeam.value || undefined),
      page: usersCheckinsPaginator?.page,
    }),
    [
      checkinsDates,
      monthCheckinsFilter,
      week,
      userFilter,
      usersCheckinsPaginator,
    ],
  );

  const {isLoading: loader} = useQuery(
    ['checkinsDirectReport', filters],
    () => fetchListOfCheckins(filters),
    {enabled: updateDate},
  );

  useEffect(() => {
    if (
      (week.week && week.week !== checkinsDates.weekOfYear) ||
      (week.year && week.year !== checkinsDates.year)
    ) {
      const getWeek = calendarData.currentMonthWeeks.find(
        (_week) => _week.weekOfYear === Number(week?.week),
      );

      setCheckinsDate({
        starts: getWeek?.dateStart,
        ends: getWeek?.dateEnd,
        weekOfYear: Number(week.week),
        month: getMonthFromWeek(
          Number(week.year || new Date().getFullYear()),
          Number(week.week),
        ),
        year: Number(week.year) || new Date().getFullYear(),
      });
    }
  }, [checkinsDates, week, calendarData, setCheckinsDate]);

  useEffect(() => {
    if (calendarWeeksDirectReports.length > 0 && !week.week && !updateDate) {
      const lastWeek =
        calendarWeeksDirectReports[calendarWeeksDirectReports.length - 1];

      setCheckinsDate({
        starts: dayjs(
          getWeekRangeWorkDays(getWeekOfYear(), new Date().getFullYear())
            .starts,
        ).format('MM/DD/YYYY'),
        ends: dayjs(
          getWeekRangeWorkDays(getWeekOfYear(), new Date().getFullYear()).ends,
        ).format('MM/DD/YYYY'),
        weekOfYear: lastWeek.weekOfYear,
        month: getMonthFromWeek(lastWeek.year, lastWeek.weekOfYear),
        year: lastWeek.year,
      });
      setUpdateDate(true);
    }
  }, [setCheckinsDate, calendarWeeksDirectReports, week, updateDate]);

  useEffect(() => {
    return clearPagination;
  }, [clearPagination]);

  const activeCalWeeks = useMemo(() => {
    return calendarData.currentMonthWeeks
      .filter(
        (week) =>
          week.monthEnd === checkinsDates?.month &&
          new Date(week.dateStart) <= new Date(Date.now()),
      )
      .map((week) => {
        if (
          calendarWeeksDirectReports
            ?.map((calWk: any) => calWk.year)
            .includes(week?.year) &&
          calendarWeeksDirectReports
            ?.map((calWk: any) => calWk.weekOfYear)
            .includes(week?.weekOfYear)
        ) {
          return {...week, isCheckins: true};
        } else {
          return week;
        }
      })
      .filter((week: any) => week?.isCheckins === true);
  }, [
    calendarData.currentMonthWeeks,
    calendarWeeksDirectReports,
    checkinsDates?.month,
  ]);

  const fetchCheckinsCount = useCallback(
    async (filter?: {
      weekOfYear?: number;
      year?: number;
      isActive?: boolean;
      isSubmitted?: boolean;
      isReviewed?: boolean;
      reviewer?: string;
      manager?: string;
      user?: string;
      page?: number;
    }) => {
      const request = new CompanyCheckinsRequestImpl();
      const controller = new CompanyCheckinsController(request);

      const response = await controller.getListOfCheckins(filter);

      return response;
    },
    [],
  );

  const weekQueries = useQueries(
    activeCalWeeks.map((week) => ({
      queryKey: [
        'weekCheckinCount',
        week.year,
        week.weekOfYear,
        JSON.stringify(userFilter),
      ],
      queryFn: () =>
        fetchCheckinsCount({
          weekOfYear: week.weekOfYear,
          year: week.year,
          user:
            userFilter?.members.length > 0
              ? userFilter?.members
                  .map((user: {value: string}) => user.value)
                  .join(',')
              : undefined,
          manager:
            userFilter?.members.length === 0 &&
            (userFilter.selectedTeam.value || undefined),
        }),
      enabled: !!week.weekOfYear,
    })),
  );

  const dateOptions = useMemo(() => {
    const options = [
      {value: '', label: 'Overview'},
      ...activeCalWeeks.map((week, index) => ({
        ...week,
        label: week.title,
        value: String(week.weekOfYear),
        count: weekQueries[index]?.data
          ? (weekQueries[index].data as any)?.summary?.submitted
          : 0,
      })),
    ];
    return options;
  }, [activeCalWeeks, weekQueries]);

  const memoizedDateOptions = useMemo(() => {
    return dateOptions;
  }, [dateOptions]);

  const handleCalendarWeekClick = useCallback(
    (date: any) => {
      if (location.search?.length > 0) navigate(location.pathname);
      setCheckinsDate({
        starts: date.start,
        ends: date.end,
        weekOfYear: date.weekOfYear,
      });
    },
    [location, navigate, setCheckinsDate],
  );

  const {fetchReportCheckinsQuestions} = useAnalyticsQuestionHook({});

  return {
    userFilter,
    updateUserFilter,
    clearSelection,
    loading,
    setClearSelection,
    usersCheckins,
    checkinsDates,
    configuration,
    setCalendarView,
    loader,
    moods,
    activeCalWeeks,
    dateOptions: memoizedDateOptions,
    selectedDate,
    setSelectedDate,
    fetchReportCheckinsQuestions,
    selectedQuestions,
    fetchMonthlyListOfCheckins,
    setCheckinsDate,
    setSelectedQuestions,
    handleCalendarWeekClick,
    months,
    isQuestionSelectDisabled,
    showQuestionSelectApply,
    setShowQuestionSelectApply,
    setIsQuestionSelect,
  };
}
