import {VerticalSpacer} from '@ui/atoms/spacer';
import {Body1, Body2} from '@ui/atoms/typography';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import React, {FC, memo, useCallback, useMemo, useState} from 'react';
import {Insight} from '../interface';
import {ChevronDownIcon} from '@ui/atoms/icons';
import {DropdownCustom} from '@ui/molecules/dropdown-custom';
import {DropdownItem} from '@pages/dashboard/you/you-page.styles';
import dayjs from 'dayjs';
import {AddFilter} from './add-filter';
import {SecondaryMinusSVG} from '@ui/atoms/svg/minus';
import {CustomFilterMultiSelect} from '../../reporting-filter/custom-multi-select';
import {useSections} from '@hooks/action-items-sections';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {ObjectiveFilter} from '../objectives/objective-filter';
import {formatConfigDate} from '../insight-options';
import {DateRangeInput} from '@ui/molecules/date-range-input';
import {Button} from '@ui/atoms/button';

interface Option {
  value: string;
  label: string;
}

interface Filter {
  selected: {value: string}[];
  options: {value: any; label: any}[];
  onChange: (value: string[]) => void;
}

interface FilterDetails {
  list: Filter;
  people: Filter;
}

interface EditInsightFilterProps {
  dateRangeType: Option[];
  isCustomDateRange: boolean;
  insightConfig: Insight['config'];
  updateInsight: (type: any, value: any) => void;
  dateRangeOptions: Option[];
  comparisonOptions: Option[];
  setCustomDateRange: (value: any) => void;
  canAddFilter: boolean;
  isDisabled?: boolean;
  configIsChanged: boolean;
  isKeyResults?: boolean;
  handleReset: () => void;
  type: string;
  hasComparison?: boolean;
  canSelectObjective?: boolean;
  selectedLists?: string[];
  onChangeList?: (list: string[]) => void;
  onChangeUsers: (user: string[]) => void;
  selectedUsers?: string[];
  updateName?: (name: string) => void;
  customDateRange: {
    starts: string;
    ends: string;
  };
}
export function getInitialAdditionalFilters(
  selectedLists: string[] = [],
  selectedUsers: string[] = [],
): string[] {
  if (selectedLists.length && selectedUsers.length) {
    return ['list', 'people'];
  } else if (selectedUsers.length) {
    return ['people'];
  } else if (selectedLists.length) {
    return ['list'];
  } else {
    return [];
  }
}

export const EditInsightFilter: FC<EditInsightFilterProps> = ({
  dateRangeType,
  isCustomDateRange,
  updateInsight,
  isDisabled = false,
  canSelectObjective,
  customDateRange,
  isKeyResults,
  handleReset,
  hasComparison = true,
  configIsChanged,
  type,
  updateName,
  setCustomDateRange,
  dateRangeOptions,
  canAddFilter = false,
  onChangeList,
  onChangeUsers,
  selectedLists = [],
  selectedUsers = [],
  comparisonOptions,
  insightConfig,
}) => {
  const [additionalFilters, setAdditionalFilters] = useState<string[]>(
    getInitialAdditionalFilters(selectedLists, selectedUsers),
  );

  const [_selectedUsers, setSelectedUsers] = useState(selectedUsers);

  const [_selectedList, setSelectedList] = useState(selectedLists);

  const {computeSections} = useSections();

  const {
    usersStore: {users},
  } = useStoreContext();

  const sections = computeSections.map((section) => ({
    value: section.slug,
    label: section.name,
  }));

  const allUsers = users.map((user) => ({
    value: user.id,
    label: userName(user),
  }));

  const dateRange = useMemo(() => formatConfigDate(insightConfig), [
    insightConfig,
  ]);

  const handleDateRangeChange = useCallback(
    (value: {starts: string; ends: string}) => {
      setCustomDateRange(value);
    },
    [setCustomDateRange],
  );

  const filterDetails: FilterDetails = useMemo(
    () => ({
      list: {
        selected: sections.filter((section) =>
          _selectedList.includes(section.value),
        ),
        options: sections,
        onChange: (value: string[]) => {
          setSelectedList(value);
          onChangeList?.(value);
        },
      },
      people: {
        selected: allUsers.filter((user) =>
          _selectedUsers.includes(user.value),
        ),
        options: allUsers,
        onChange: (value: string[]) => {
          setSelectedUsers(value);
          onChangeUsers?.(value);
        },
      },
    }),
    [
      _selectedList,
      _selectedUsers,
      allUsers,
      onChangeList,
      onChangeUsers,
      sections,
    ],
  );

  const disableReset = isDisabled || !configIsChanged;

  return (
    <div className="bg-[#F6F6F8] min-h-[95vh] h-full py-4 px-6 rounded-[10px] border border-[#E1E1EA] min-w-[30%]">
      <FlexRowSpaceBetween>
        <Body1 weight="bold">Filters</Body1>

        <button type="button" disabled={disableReset} onClick={handleReset}>
          <Body2
            weight="semibold"
            kind={!disableReset ? 'purple300' : 'textMuted'}>
            Reset
          </Body2>
        </button>
      </FlexRowSpaceBetween>
      <VerticalSpacer size="24px" />
      <Body1 weight="bold" className="mt-4">
        Date range
      </Body1>

      <VerticalSpacer size="16px" />

      <CustomDropDown
        isDisabled={isDisabled}
        options={dateRangeType}
        value={insightConfig.type}
        onChange={(value) => {
          updateInsight('type', value);

          updateInsight('comparison', {
            type: 'day',
            value: value === 'periods' ? '90' : value === 'custom' ? '' : '28',
          });
        }}
      />

      <VerticalSpacer size="8px" />

      {isCustomDateRange && (
        <div className="mt-4">
          <DateRangeInput
            name="dateRange"
            isDisabled={isDisabled}
            value={{
              starts: customDateRange.starts
                ? dayjs(customDateRange.starts, 'DD-MM-YY').format()
                : '',
              ends: customDateRange.ends
                ? dayjs(customDateRange.ends, 'DD-MM-YY').format()
                : '',
            }}
            placeholder="Select date"
            block
            dateAlign="left"
            setValue={(value) => {
              handleDateRangeChange({
                starts: value.starts
                  ? dayjs(value.starts).format('DD-MM-YY')
                  : '',
                ends: value.ends ? dayjs(value.ends).format('DD-MM-YY') : '',
              });
            }}
          />
        </div>
      )}

      <VerticalSpacer size="8px" />

      {!isCustomDateRange && (
        <>
          <CustomDropDown
            options={dateRangeOptions}
            isDisabled={isDisabled}
            value={String(insightConfig.range.value)}
            onChange={(value) => {
              const isPeriod = insightConfig.type === 'periods';

              if (!['action-items', 'objectives'].includes(type)) {
                updateInsight('comparison', {
                  type: 'day',
                  value: insightConfig.type === 'dynamic' ? value : '',
                });
              }

              if (value.toLowerCase() === 'custom') {
                return;
              }

              updateInsight('defaultPeriod', false);

              updateInsight('range', {
                type: isPeriod
                  ? 'periods'
                  : insightConfig.type === 'custom'
                  ? 'custom'
                  : 'day',

                value,
              });
            }}
          />
          <VerticalSpacer size="24px" />
        </>
      )}

      {hasComparison && (
        <div className="pb-6 border-b border-b-borderLight">
          <Body1 weight="bold" className="mt-4">
            Compared to
          </Body1>
          <VerticalSpacer size="8px" />

          <CustomDropDown
            options={comparisonOptions}
            isDisabled={isDisabled}
            onChange={(value) =>
              updateInsight('comparison', {
                type: 'day',
                value,
              })
            }
            value={String(insightConfig.comparison.value)}
          />
        </div>
      )}
      <VerticalSpacer size="24px" />
      {additionalFilters.map((filter) => (
        <div key={filter} className="border-b border-b-borderLight pb-6 mb-4">
          <FlexRowSpaceBetween className="mb-4">
            <Body2 className="capitalize" weight="bold">
              {filter}
            </Body2>

            <button
              onClick={() => {
                setAdditionalFilters((prev) =>
                  prev.filter((_filter) => _filter !== filter),
                );

                filterDetails[filter as 'list'].onChange([]);
              }}>
              <FlexRow>
                <SecondaryMinusSVG />
                <Body2 kind="red400" weight="bold" className="">
                  remove
                </Body2>
              </FlexRow>
            </button>
          </FlexRowSpaceBetween>

          <CustomFilterMultiSelect
            selectedOptionStyle={{background: 'white'}}
            options={filterDetails[filter as 'list'].options}
            // isDisabled={isDisabled}
            onChange={(value: Option[]) =>
              filterDetails[filter as 'list'].onChange(
                value.map((option) => option.value),
              )
            }
            // disabled={!!breakdownBy}
            placeholderTerm={filter}
            selectedOptions={(filterDetails as any)[filter].selected}
          />
        </div>
      ))}

      {type === 'objectives' && (
        <ObjectiveFilter
          {...dateRange}
          isKeyResults={isKeyResults}
          canAddObjective={canSelectObjective}
          isDisabled={isDisabled}
          handleChange={(field, value) => {
            if (field === 'goal') {
              updateInsight('objectives', value);
              return;
            }
            updateInsight(field, value);
          }}
          groups={insightConfig.groups}
          objectives={insightConfig.objectives}
          keyResult={insightConfig.key_result}
          objectiveType={insightConfig.objectiveType}
        />
      )}

      {canAddFilter && !isDisabled && (
        <AddFilter
          filters={additionalFilters}
          isDisabled={isDisabled}
          updateFilters={(value) =>
            setAdditionalFilters((prev) => [...prev, value])
          }
        />
      )}
    </div>
  );
};

interface CustomDropDownProps {
  options: {value: string; label: string}[];
  value: string;
  isDisabled?: boolean;
  onChange: (value: string) => void;
}

export const CustomDropDown = memo(
  ({options, value, onChange, isDisabled}: CustomDropDownProps) => {
    const label = options.find((option) => option.value === value)?.label;

    return (
      <>
        <DropdownCustom
          collapseOnClick
          customComponent={(handleOpen: (event: any) => void) => (
            <Button
              onClick={handleOpen}
              disabled={isDisabled}
              kind="secondary"
              style={{padding: '12px 16px', fontWeight: 400}}
              className="rounded-[10px] min-h-[46px] border bg-white w-full border-borderDark flex items-center justify-between">
              {label || 'Select'}

              <ChevronDownIcon color={isDisabled ? '#BFBFD4' : undefined} />
            </Button>
          )}
          menu={(handleClose: () => void) => (
            <div>
              {options.map((option) => (
                <DropdownItem
                  key={option.value}
                  onClick={() => {
                    handleClose();
                    onChange(option.value);
                  }}>
                  {option.label}
                </DropdownItem>
              ))}
            </div>
          )}
          customDropdownWrapperStyles={{
            top: '130%',
            right: '0px',
            width: '100%',
          }}
        />
      </>
    );
  },
);
