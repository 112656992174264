import {
  getDatabase,
  push,
  ref,
  set,
  remove,
  update,
  get,
} from 'firebase/database';
import firebaseConfig from './firebase';

import {authStore} from '@store/stores/auth-store';

const db = getDatabase(firebaseConfig);

export async function writeFirebaseData(path: string, body: any) {
  const postListRef = ref(
    db,
    `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/${path}`,
  );

  const newPostRef = push(postListRef);
  await set(newPostRef, body);

  return newPostRef.key;
}

export async function getFirebaseData(path: string) {
  try {
    const dbRef = ref(
      db,
      `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/${path}`,
    );

    const snapshot = await get(dbRef);

    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
export async function setFirebaseData(path: string, body: any) {
  const postListRef = ref(
    db,
    `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/${path}`,
  );

  await set(postListRef, body);

  return postListRef.key;
}

export const removeFirebaseData = async (path: string) => {
  const dbRef = ref(
    db,
    `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/${path}`,
  );

  await remove(dbRef);
};

export const updateFirebaseData = async (path: string, body: any) => {
  await update(
    ref(
      db,
      `${process.env.REACT_APP_DEV_ENV}/${authStore.auth.user.workspace.id}/${path}`,
    ),
    body,
  );
};
