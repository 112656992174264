import {useState, useCallback} from 'react';
import {useQueryClient} from 'react-query';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {FeedbackRequestImpl} from '@pages/feedback/feedback-requests';
import {FeedbackController} from '@pages/feedback/feedback-controller';
import {beamMasteryTasks} from '@utils/firebase-request';
import {usePostHogHook} from '@hooks/post-hog';
import {PatchMeetingFormData} from '../view-meeting-hook';
import {removeEmptyStringFromObject} from '@utils/data-structure-algos';
import {useGCalenderHook} from '@hooks/gcalender';
import {useNavigate} from 'react-router';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {writeFirebaseData} from '@utils/firebase-handler';
import {authStore} from '@store/stores/auth-store';
import {useActionItems} from '@hooks/features-action-items';

export const useMeetingActions = (meetingId: string) => {
  const [submitting, setSubmitting] = useState(false);
  const [loadingFeedback, setLoadingFeedback] = useState(false);

  const {deleteEvent, isSigned} = useGCalenderHook();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const {postHogCapture} = usePostHogHook();

  const handleUpdateMeeting = useCallback(
    async (id: string, data: PatchMeetingFormData): Promise<any> => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const _data = {
        ...data,
        participant: data.participant
          ? (data.participant as any)?.map((participant: any) =>
              typeof participant === 'string' ? participant : participant?.id,
            )
          : undefined,
      };

      return await controller.patchMeeting(
        removeEmptyStringFromObject(_data),
        id,
      );
    },
    [],
  );

  const submitMeeting = useCallback(
    async (feedbackId?: string) => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      const response = await controller.patchMeeting(
        {
          status: 'completed',
          feedback: feedbackId,
        },
        meetingId,
      );

      beamMasteryTasks('complete_meeting', true);
      postHogCapture('frontend - 1:1 meeting conclusion');

      return response;
    },
    [meetingId, postHogCapture],
  );

  const markAsCompleted = useCallback(
    async (
      name: string,
      feedbackValues: {
        feedbackValue: string;
        impression: string;
        feedbackSharingType: string;
        values: string[];
      },
      userId: string,
    ) => {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);

      setSubmitting(true);

      try {
        if (feedbackValues.feedbackValue && feedbackValues.impression) {
          const response = await controller.postFeedback(
            {
              feedback: feedbackValues.feedbackValue,
              impression: feedbackValues.impression,
              subjectType: '1:1',
              subjectName: `1:1 with ${name}`,
              subjectId: meetingId,
              visibility: feedbackValues.feedbackSharingType,
              values: feedbackValues.values,
            },
            userId,
          );

          if (response) {
            postHogCapture('frontend - feedback submission');
            return await submitMeeting(response.id);
          }
        }

        return await submitMeeting();
      } finally {
        setSubmitting(false);
      }
    },
    [meetingId, postHogCapture, submitMeeting],
  );
  const getUserFeedBack = useCallback(async (id: string, user: string) => {
    try {
      const request = new FeedbackRequestImpl();
      const controller = new FeedbackController(request);

      setLoadingFeedback(true);
      const response = await controller.getFeedback(
        {subjectId: id, subjectType: '1:1'},
        user,
      );
      setLoadingFeedback(false);
      return response;
    } catch {}
  }, []);

  const deleteMeeting = useCallback(
    async (
      calendarId?: string,
      id?: string,
      primaryMeeting?: string,
    ): Promise<any> => {
      if (!isSigned) return;

      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      try {
        if (calendarId) await deleteEvent(calendarId, 'primary');

        if (id) await controller.deleteMeeting(id);

        if (primaryMeeting) {
          const response = await controller.getMeeting(primaryMeeting);

          if (response) {
            const updatedCalendar = response.connectedCalendar.filter(
              (calendar: {meetingId: string}) => calendar.meetingId !== id,
            );
            await controller.patchMeeting(
              {
                connectedCalendar: updatedCalendar.map((calendar: any) => ({
                  ...calendar,
                  _id: undefined,
                })),
              },
              primaryMeeting,
            );
          }
        }
        activateNotification({
          kind: 'success',
          content: 'Deleted successfully',
          title: 'Success',
        });

        // navigate to main meeting because only main meetings have a calendarId
        if (calendarId) {
          navigate('/meetings/');
          return;
        }

        if (primaryMeeting) {
          navigate(`/view-meetings/${primaryMeeting}`);
        } else {
          navigate('/meetings');
        }

        if (id) return {success: true};
      } catch (error) {
        activateNotification({
          kind: 'error',
          content: 'Error deleting event',
          title: 'Success',
        });
        throw error;
      }
    },
    [deleteEvent, isSigned, navigate],
  );

  const {tasks} = useActionItems({
    source: 'meeting',
    userId: '',
    itemId: '',
    orderBy: {
      value: 'meeting',
      key: 'source',
    },
  });

  const handleCopyPreviousActionItems = (
    nextSteps: {text: string; completed: boolean}[],
    id: string,
    currentSteps: {text: string; completed: boolean}[],
  ) => {
    // Create a Set of currentSteps texts for quick lookup
    const currentStepsSet = new Set(currentSteps.map((step) => step.text));

    // Filter nextSteps to include only those not present in currentSteps
    const filteredNextSteps = nextSteps.filter(
      (step) => !currentStepsSet.has(step.text),
    );

    const getPreviousData = tasks.filter((task) =>
      filteredNextSteps.some(
        (step) => step.text === task.text && step.completed === task.completed,
      ),
    );

    // Copy the filtered steps to Firebase
    getPreviousData
      .filter((step) => !step.completed)
      .forEach((step) => {
        writeFirebaseData('action_items', {
          ...step,
          source: 'meeting',
          sourceId: id,
        });
      });
  };

  const handleBringForwardActionItems = async (meta: any) => {
    handleUpdateMeeting(meetingId, {
      meta: {...meta, bringForwardActionItems: true},
    } as any).then(() => {
      queryClient.setQueryData(['view-meeting', meetingId], (oldData: any) => {
        if (!oldData) return oldData;

        return {
          ...oldData,
          meta: {...meta, bringForwardActionItems: true},
        };
      });
    });
  };

  const handleBringForwardAgenda = async (meetingCode: string) => {
    const request = new MeetingRequestImpl();

    const controller = new MeetingController(request);

    const meetings = await controller.getMeetings({
      meetingCode,
    });

    if (meetings) {
      const currentMeeting = meetings.results[0];

      const previousMeeting = meetings.results[1];

      const prevHostAgenda = previousMeeting.hostAgenda?.filter(
        (agenda: {completed: boolean}) => !agenda.completed,
      );

      const prevParticipantAgenda = previousMeeting.participantAgenda?.filter(
        (agenda: {completed: boolean}) => !agenda.completed,
      );

      const isHost = currentMeeting?.user?.id === authStore.auth.user.id;

      handleUpdateMeeting(
        meetingId,
        isHost
          ? {
              hostAgenda: [...prevHostAgenda, ...currentMeeting.hostAgenda],
              meta: {...currentMeeting.meta, bringForwardAgenda: true},
            }
          : ({
              participantAgenda: [
                ...prevParticipantAgenda,
                ...currentMeeting.participantAgenda,
              ],
              meta: {...currentMeeting.meta, bringForwardActionItems: true},
            } as any),
      ).then(() => {
        queryClient.setQueryData(
          ['view-meeting', meetingId],
          (oldData: any) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              meta: {...currentMeeting.meta, bringForwardAgenda: true},
              hostAgenda: isHost
                ? [...prevHostAgenda, ...currentMeeting.hostAgenda]
                : oldData.hostAgenda,
              participantAgenda: !isHost
                ? [
                    ...prevParticipantAgenda,
                    ...currentMeeting.participantAgenda,
                  ]
                : oldData.participantAgenda,
            };
          },
        );
      });
    }
  };

  return {
    handleUpdateMeeting,
    markAsCompleted,
    deleteMeeting,
    submitting,
    loadingFeedback,
    handleBringForwardAgenda,
    handleBringForwardActionItems,
    handleCopyPreviousActionItems,
    getUserFeedBack,
  };
};
