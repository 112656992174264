import {usePeriodHook} from '@hooks/period-hook';
import {useStoreContext} from '@store/store-context';
import {authStore} from '@store/stores/auth-store';
import {writeFirebaseData, updateFirebaseData} from '@utils/firebase-handler';
import dayjs from 'dayjs';
import {useCallback, useMemo, useState} from 'react';
import {Insight} from '../interface';
import {getInitialAdditionalFilters} from './edit-insight-filter';
import {activateNotification} from '@ui/molecules/notification/activate-notification';
import {cfv} from '@utils/framework';
import {capitalize} from '@utils';

const start = dayjs().startOf('quarter');

const end = start.clone().endOf('quarter');

const periodDateRange = `${dayjs(start).format('MM/DD/YYYY')} - ${dayjs(
  end,
).format('MM/DD/YYYY')}`;

export const DEFAULT_KPI_CONFIG = {
  type: 'dynamic',
  range: {
    type: 'day',
    value: '28',
  },
  comparison: {
    type: 'day',
    value: '28',
  },
  lists: [],
  users: [],
};

export const DEFAULT_ACTION_ITEMS_CONFIG = {
  type: 'dynamic',
  comparison: {
    type: 'day',
    value: '',
  },
  range: {
    type: 'day',
    value: '28',
  },

  lists: [],
  users: [],
};

export const DEFAULT_OBJECTIVES_CONFIG = {
  type: 'periods',
  hasComparison: false,
  comparison: {
    type: 'day',
    value: '',
  },
  range: {
    type: 'periods',
    value: periodDateRange,
  },
  objectiveType: '',
  defaultPeriod: true,

  groups: [],
  objectives: [],
};

export const DEFAULT_OBJECTIVES_STATUS_CONFIG = {
  type: 'periods',
  hasComparison: false,
  comparison: {
    type: 'day',
    value: '',
  },
  range: {
    type: 'periods',
    value: periodDateRange,
  },
  objectiveType: '',
  defaultPeriod: true,
  groups: [],
};

export const DEFAULT_KEY_RESULT_CONFIG = {
  type: 'periods',
  hasComparison: false,
  comparison: {
    type: 'day',
    value: '',
  },
  range: {
    type: 'periods',
    value: periodDateRange,
  },
  defaultPeriod: true,
  key_result: '',
};

export const DEFAULT_NAME = (id: string) => {
  if (id === 'key-results-performance') {
    return `${capitalize(cfv().k_r)} performance`;
  }
  if (id === 'objective-status') {
    return `${capitalize(cfv().g_oal)} status`;
  }
  if (id === 'objective-completion') {
    return `${capitalize(cfv().g_oal)} completion`;
  }
  return '';
};

const DEFAULT_CONFIG = (
  type: string,
  id: string,
  removeRangeValue?: boolean,
) => {
  const emptyRangeValue = (data: any) =>
    removeRangeValue
      ? {
          ...data,
          range: {...data.range, value: ''},
        }
      : data;

  if (id === 'key-results-performance') {
    return emptyRangeValue(DEFAULT_KEY_RESULT_CONFIG);
  }
  if (id === 'objective-status') {
    return emptyRangeValue(DEFAULT_OBJECTIVES_STATUS_CONFIG);
  }
  if (id === 'objective-completion') {
    return emptyRangeValue({
      ...DEFAULT_OBJECTIVES_STATUS_CONFIG,
      objectives: [],
    });
  }
  switch (type) {
    case 'kpi':
      return DEFAULT_KPI_CONFIG;
    case 'action-items':
      return DEFAULT_ACTION_ITEMS_CONFIG;
    case 'objectives':
      return DEFAULT_OBJECTIVES_CONFIG;
    default:
      return DEFAULT_KPI_CONFIG;
  }
};

export const handleInsightName = (name: string, initialName?: string) => {
  let newName = name;

  if (newName.match(/\(copy-(\d+)\)$/)) {
    const currentNumber = parseInt(
      newName?.match(/\(copy-(\d+)\)$/)?.[1] || '',
    );
    newName = newName.replace(/\(copy-\d+\)$/, `(copy-${currentNumber + 1})`);
  } else if (newName.endsWith('(copy)')) {
    newName = newName.replace('(copy)', '(copy-1)');
  } else if (name === initialName) {
    newName += ' (copy)';
  }

  return newName;
};
interface EditInsightHook extends Omit<Insight, 'createdBy' | 'updatedAt'> {
  onClose: () => void;
  updateEditInsight?: (id: string) => void;
}
export const useEditInsight = ({
  name,
  firebaseId,
  config,
  type,
  onClose: _,
  section,
  updateEditInsight,
  ...rest
}: EditInsightHook) => {
  const defaultConfig = DEFAULT_CONFIG(type, rest.id, true);

  const config_default = DEFAULT_CONFIG(type, rest.id);

  const [insightConfig, setInsightConfig] = useState(config);

  const [insightName, setInsightName] = useState(name);

  const [isEditingName, setIsEditingName] = useState(false);

  const [isEditing, setIsEditing] = useState(false);

  const [nameText, setNameText] = useState('');

  const [initialName] = useState(name);

  const [isChanged, setIsChanged] = useState(
    JSON.stringify(config_default) !== JSON.stringify(insightConfig),
  );

  const {
    usersStore: {users},
  } = useStoreContext();

  const KPIOptions = [
    {
      title: 'Number',
      key: 'number',
    },
    {
      title: 'Line chart & Number',
      key: 'chart',
    },
  ];

  const findViewType = (type: string) =>
    KPIOptions.find((option) => option.key === type)?.title ||
    KPIOptions[0].title;

  const findUser = (userId: string) => users.find((user) => user.id === userId);

  const splitDate =
    insightConfig.type === 'custom' && insightConfig.range.value
      ? insightConfig.range.value?.split(' - ')
      : ['', ''];

  const [customDateRange, setCustomDateRange] = useState({
    starts: splitDate[0],
    ends: splitDate[1],
  });

  const updateCustomDateRange = (value: any) => {
    const updatedRange = {...value};

    setCustomDateRange(updatedRange);

    if (updatedRange.ends && updatedRange.starts) {
      setInsightConfig((prev) => ({
        ...prev,
        range: {
          ...prev.range,
          type: prev.type === 'custom' ? 'custom' : prev.range.type,
          value:
            prev.type === 'custom'
              ? `${updatedRange.starts} - ${updatedRange.ends}`
              : prev.range.value,
        },
      }));
    }
  };

  const handleSaveName = async (name: string) => {
    const data = {
      ...rest,
      config,
      type,
      name,
      updatedAt: dayjs().format(),
    };

    await updateFirebaseData(`insights/${firebaseId}`, data);
  };

  const handleSave = useCallback(
    async (newSave?: boolean, isReset?: boolean) => {
      const data = {
        ...rest,
        config: {
          ...insightConfig,
          range: {
            ...insightConfig.range,
            value:
              insightConfig.type === 'custom'
                ? `${customDateRange.starts} - ${customDateRange.ends}`
                : insightConfig.range.value,
          },
        },
        type,
        name: insightName,
        updatedAt: dayjs().format(),
      };

      const notify = () =>
        activateNotification({
          title: 'Success',
          content: isReset
            ? 'Insight reset successful'
            : 'Insight saved successfully',
          kind: 'success',
        });

      if (newSave) {
        const newName = handleInsightName(insightName, initialName);

        const response = await writeFirebaseData(`insights`, {
          ...data,
          name: newName,
          createdBy: authStore.auth.user.id,
          section: '',
          createdAt: dayjs().format(),
          updatedBy: authStore.auth.user.id,
        });

        if (response) {
          updateEditInsight?.(response);

          setInsightName(newName);
        }

        notify();

        return;
      }

      notify();

      await updateFirebaseData(`insights/${firebaseId}`, data);
    },
    [
      customDateRange.ends,
      customDateRange.starts,
      firebaseId,
      initialName,
      insightConfig,
      insightName,
      rest,
      type,
      updateEditInsight,
    ],
  );

  const handleDuplicate = async () => {
    const data = {
      ...rest,
      config: insightConfig,
      name: handleInsightName(insightName, initialName),
      type,
      updatedAt: dayjs().format(),
    };

    await writeFirebaseData(`insights`, {
      ...data,
      createdBy: authStore.auth.user.id,
      createdAt: dayjs().format(),
      updatedBy: authStore.auth.user.id,
    });
  };

  const isIncludedInDashboard = !!section;

  const {periods} = usePeriodHook();

  const dateRangeType = useMemo(() => {
    const options = [
      {
        value: 'periods',
        label: 'Quarterly periods',
      },
      {
        value: 'custom',
        label: 'Custom date range',
      },
    ];

    if (type !== 'objectives') {
      options.unshift({
        value: 'dynamic',
        label: 'Dynamic data range',
      });
    }
    return options;
  }, [type]);

  const dateRangeOptions = useMemo(() => {
    if (insightConfig.type === 'periods') {
      const mappedPeriods = periods.map((period) => ({
        ...period,
        value: `${period.starts} - ${period.ends}`,
      }));

      mappedPeriods.pop();

      return mappedPeriods;
    }

    if (insightConfig.type === 'dynamic') {
      return [
        {
          value: '7',
          label: 'Last 7 days',
        },
        {
          value: '14',
          label: 'Last 14 days',
        },
        {
          value: '28',
          label: 'Last 28 days',
        },
        {
          value: '60',
          label: 'Last 60 days',
        },
        {
          value: '90',
          label: 'Last 90 days',
        },
        {
          value: '180',
          label: 'Last 180 days',
        },
        {
          value: '360',
          label: 'This Year',
        },
      ];
    }

    return [];
  }, [insightConfig.type, periods]);

  const comparisonOptions = useMemo(() => {
    const options = [
      {
        value: '',
        label: 'No comparison',
      },
    ];

    if (insightConfig.type === 'periods') {
      options.push({
        label: 'Previous quarter',
        value: '90',
      });
    } else if (insightConfig.type === 'dynamic') {
      const selectedDays = Number(insightConfig.range.value);
      if (selectedDays === 365) {
        options.push({
          label: `This year`,
          value: String(selectedDays),
        });
      } else {
        options.push({
          label: `Previous ${selectedDays} days`,
          value: String(selectedDays),
        });
      }
    } else if (insightConfig.type === 'custom') {
      const startDate = dayjs(customDateRange.starts, 'DD-MM-YY');

      const endDate = dayjs(customDateRange.ends, 'DD-MM-YY');

      const daysDifference = endDate.diff(startDate, 'day') + 1;

      if (!isNaN(daysDifference))
        options.push({
          label: `Previous ${daysDifference} days`,
          value: String(daysDifference),
        });
    }

    return options;
  }, [
    insightConfig.type,
    insightConfig.range.value,
    customDateRange.starts,
    customDateRange.ends,
  ]);

  const updateInsight = (
    field: keyof typeof insightConfig | 'goalName',
    value: any,
  ) => {
    if (field === 'goalName') {
      setInsightName(value);
      return;
    }
    if (rest.id === 'objective-completion') {
      if (field === 'objectives' && !Array.isArray(value)) {
        value = [value];
      }
    }

    const updatedConfig = {...insightConfig, [field]: value};

    setInsightConfig((prev) => ({...prev, [field]: value}));

    if (field === 'objectiveType') {
      const statusDefaultName =
        rest.id === 'objective-status' ? `${cfv().g_oal} status` : '';

      const completionDefaultName =
        rest.id === 'objective-completion' ? `${cfv().g_oal} completion` : '';

      const names = {
        all: 'All',
        individual: 'Self-development',
        group: 'Group',
        company: 'Company-wide',
      };

      setInsightName(
        `${(names as any)[value]} ${
          statusDefaultName || completionDefaultName
        }`,
      );
    }
    setIsChanged(
      JSON.stringify(updatedConfig) !== JSON.stringify(defaultConfig),
    );
  };

  const isCustomDateRange = insightConfig.type === 'custom';

  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );

  const canAddFilter = type === 'action-items';

  const handleReset = useCallback(() => {
    if (['objective-status', 'objective-completion'].includes(rest.id)) {
      setInsightName(DEFAULT_NAME(rest.id));
    }
    setInsightConfig(config_default);

    setCustomDateRange({starts: '', ends: ''});

    setIsChanged(false);

    handleSave(false, true);

    setIsEditing(false);
  }, [config_default, handleSave, rest.id]);

  return {
    setInsightConfig,
    comparisonOptions,
    dateRangeOptions,
    configIsChanged: isChanged,
    handleReset,
    dateRangeType,
    additionalFilters,
    handleDuplicate,
    isIncludedInDashboard,
    updateCustomDateRange,
    handleSave,
    config_default,
    setCustomDateRange,
    nameText,
    setIsEditing,
    isEditing,
    insightConfig,
    findViewType,
    isCustomDateRange,
    updateInsight,
    customDateRange,
    insightName,
    KPIOptions,
    canAddFilter,
    setNameText,
    isEditingName,
    setInsightName,
    setIsEditingName,
    handleSaveName,
    findUser,
  };
};
