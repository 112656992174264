import {CSSProperties, memo, ReactElement} from 'react';
import styled from 'styled-components';
import {useDateRangeInput} from '../../../hooks';
import {InputState} from '../../interface';
import {Helper} from '../../atoms/helper';
import {FieldWrapper} from '../../atoms/field-wrapper';

import {DatePicker} from '../date-picker';
import {down} from 'styled-breakpoints';

const Content = styled.div<{block?: boolean}>`
  display: grid;
  grid-template-columns: ${(props: any) => (props.block ? 'none' : '1fr 1fr')};
  grid-gap: 16px;
  align-items: center;
  padding-bottom: 3px;

  ${down('lg')} {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
`;

export interface DateRangeInputProps {
  setValue?: (value: {starts: string; ends: string}) => void;
  state?: InputState;
  helper?: string | ReactElement;
  name: string;
  defaultValue?: {starts: string; ends: string};
  value?: {starts: string; ends: string};
  placeholder?: string;
  isDisabled?: boolean;
  dateAlign?: 'left';
  inputStyle?: CSSProperties;
  disabledBefore?: string;
  disabledAfter?: string;
  admin?: boolean;
  block?: boolean;
}

export const DateRangeInput = memo(function ({
  state,
  helper,
  setValue,
  defaultValue,
  value,
  disabledBefore,
  disabledAfter,
  inputStyle,
  isDisabled,
  placeholder,
  dateAlign,
  admin,
  block,
}: DateRangeInputProps) {
  const {
    starts,
    ends,
    onUpdateEnds,
    onUpdateStart,
    errorType,
  } = useDateRangeInput(defaultValue, value, setValue, state);

  return (
    <FieldWrapper
      helper={helper === '' || undefined ? false : true}
      margin={admin}>
      <Content block={block}>
        <div>
          <DatePicker
            label={block ? 'Start' : 'Starts'}
            placeholder={starts ? '' : placeholder}
            disabled={isDisabled}
            disabledBefore={disabledBefore}
            dateAlign={dateAlign}
            inputStyle={inputStyle}
            disabledAfter={disabledAfter}
            value={starts}
            onChange={onUpdateStart}
          />
        </div>
        <div>
          <DatePicker
            label={block ? 'End' : 'Ends'}
            disabledBefore={starts}
            inputStyle={inputStyle}
            disabledAfter={disabledAfter}
            disabled={isDisabled}
            dateAlign={dateAlign}
            placeholder={ends ? '' : placeholder}
            value={ends}
            onChange={onUpdateEnds}
          />
        </div>
      </Content>
      <Helper
        aria-label="helper"
        state={state === 'error' ? 'error' : errorType}
        children={helper}
      />
    </FieldWrapper>
  );
});

DateRangeInput.displayName = 'DateRangeInput';
