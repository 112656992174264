import {useSections} from '@hooks/action-items-sections';
import {useStoreContext} from '@store/store-context';
import {Button} from '@ui/atoms/button';
import {MoreVerticalIcon, CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline2, Body2} from '@ui/atoms/typography';
import {ModalCard} from '@ui/layouts/modal-card';
import {SectionMenu} from '@ui/layouts/section-card/section-toggle';
import {BackAction} from '@ui/molecules/back-action';
import {DropdownVertical} from '@ui/molecules/dropdown-vertical';
import {TextField} from '@ui/molecules/field/textfield';
import {Modal} from '@ui/molecules/modal';
import {ActionItems} from '@ui/organisms/action-items/action-items';
import {CustomIconModal} from '@ui/organisms/modal/custom-icon-modal';
import {FlexRow} from '@ui/style/styles';
import {removeUndefinedFromObject} from '@utils/data-structure-algos';
import {updateFirebaseData} from '@utils/firebase-handler';
import {
  db,
  FIREBASE_BASE_URL,
  updateActionItemsActivities,
  updateActionItemsFilter,
} from '@utils/firebase-request';
import {ActionType} from '@api/action-items-api/action-items-request';

import {userName} from '@utils/user-name';
import dayjs from 'dayjs';
import {memo, useCallback, useMemo, useState} from 'react';
import validator from 'validator';
import {DropdownItem} from '../you/you-page.styles';
import {ModalWrapper} from '../groups/styles';
import {UserAPIRequestImpl} from '@api/user-api/user-api-request';
import {UserAPIController} from '@api/user-api/user-api-controller';
import {filterDuplicateString} from '@utils/array';
import {get, ref} from 'firebase/database';

type Action =
  | 'dueDate'
  | 'user'
  | 're-sort'
  | ''
  | 'remove'
  | 'remove-link'
  | 'add-url';

interface ActionItemsSectionProps {
  getSourceDetail: (sourceId: string) => any;
  goalOptions: any[];
  selectedUser: string;
  actionItems: any[];
  name: string;
  sectionSlug: string;
  sources?: any;
  followers?: string[];
  handleChange: (
    value: ActionType[],
    actionType?: Action,
    fieldId?: string,
    item?: ActionType,
  ) => void;
  sortBy?: 'createdAt' | 'dueDate' | 'order';
  setViewSource: any;
  data: any[];
  refetchSources?: Function;
}

export const ActionItemsSections = memo(
  ({
    goalOptions,
    name,
    sortBy,
    actionItems = [],
    setViewSource,
    followers = [],
    data,
    refetchSources,
    sectionSlug,
    selectedUser,
    handleChange,
  }: ActionItemsSectionProps) => {
    const [showAddUrl, setShowAddUrl] = useState('');

    const {
      usersStore: {users},
      authStore: {auth},
    } = useStoreContext();

    // Sections
    const {
      setSectionTitle,
      handleEditSection,
      showRenameSectionModal,
      setRenameSectionModal,
      showDeleteModal,
      setShowDeleteModal,
      handleDeleteSection,
      computeSections,
      sectionTitle,
    } = useSections();

    const [urlFormData, setUrlFormData] = useState({
      title: '',
      url: '',
    });

    const handleUpdateUrlFormData = useCallback(
      (field: string, value: string) => {
        setUrlFormData((prev) => ({...prev, [field]: value}));
      },
      [],
    );

    const handleSubmitNotificationRequest = useCallback(
      async (message: string, subscribers: string[]) => {
        const request = new UserAPIRequestImpl();

        const controller = new UserAPIController(request);

        await controller.requestUserNotification({
          notification: message,
          subscribers,
        });
      },
      [],
    );

    const getActionItem = useCallback(
      (id: string) =>
        actionItems.find((value: {id: string}) => value.id === id),
      [actionItems],
    );

    const handleActionItemNotification = useCallback(
      async (actionItemId: string, message: string, user?: string) => {
        const item = getActionItem(actionItemId);

        const subscribers = filterDuplicateString([
          ...followers,
          ...(item?.subscribers || []),
        ]);

        if (subscribers.includes(user || auth.user.id)) {
          await handleSubmitNotificationRequest(message, subscribers);
        }
      },
      [getActionItem, followers, auth.user.id, handleSubmitNotificationRequest],
    );

    const handleAddUrl = useCallback(
      (fieldId: string) => {
        const updatedItemIndex = actionItems.findIndex(
          (item) => item.key === fieldId,
        );

        const updatedActionItems = [...actionItems];

        updatedActionItems[updatedItemIndex] = {
          ...updatedActionItems[updatedItemIndex],
          meta: urlFormData,
          source: 'url',
        };

        const item = updatedActionItems[updatedItemIndex];

        if (item.firebaseId) {
          handleChange(updatedActionItems, 'add-url', item?.key, item);

          updateFirebaseData(
            `action_items/${item.firebaseId}`,
            removeUndefinedFromObject(item),
          );
        }

        setShowAddUrl('');
        setUrlFormData({title: '', url: ''});
        handleActionItemNotification(
          item.key,
          `_(${userName(auth.user)})_ added a URL link to ${item.text}`,
        );
      },
      [
        actionItems,
        urlFormData,
        handleChange,
        handleActionItemNotification,
        auth.user,
      ],
    );

    const handleActionItemChange = useCallback(
      async (
        value: ActionType[],
        actionType: Action,
        fieldId: string,
        item: ActionType & {key: string},
      ) => {
        const computedItem = {
          ...item,
          assignee:
            actionType === 'user'
              ? item?.assignee
              : item?.assignee || selectedUser,
        };

        if (item?.key) {
          const prevItem = getActionItem(item.key);

          const assignee =
            computedItem.assignee?.id || computedItem.assignee || '';

          const prevAssignee =
            prevItem?.assignee?.id || prevItem?.assignee || '';

          if (
            assignee &&
            assignee !== auth.user.id &&
            prevAssignee !== assignee
          ) {
            await handleSubmitNotificationRequest(
              `_(${userName(auth.user)})_ added you to _(${item.text})_`,
              [assignee],
            );
          }

          if (item.text !== prevItem?.text && prevItem?.text) {
            await handleActionItemNotification(
              item.key,
              `_(${userName(auth.user)})_ renamed  _(${prevItem.text})_ to _(${
                item.text
              })_`,
            );
          }
        }

        handleChange(value, actionType, fieldId, computedItem);

        if (actionType === 'remove-link') {
          refetchSources && refetchSources();
        }
        if (actionType === 're-sort') {
          value.forEach((actionItem) => {
            if (sectionSlug !== actionItem.section_slug) {
              handleActionItemNotification(
                actionItem.key,
                `_(${userName(auth.user)})_ moved _(${
                  actionItem.text
                })_ to another list`,
              );
            }
          });
        }
      },
      [
        selectedUser,
        handleChange,
        getActionItem,
        auth.user,
        handleSubmitNotificationRequest,
        handleActionItemNotification,
        refetchSources,
        sectionSlug,
      ],
    );

    const handleFollowActionItems = useCallback(
      async (fieldId: string) => {
        const updatedItemIndex = actionItems.findIndex(
          (item) => item.key === fieldId,
        );

        const updatedActionItems = [...actionItems];

        const actionItemsSubscribers: string[] =
          updatedActionItems[updatedItemIndex]?.subscribers || [];

        const isFollowing = actionItemsSubscribers.includes(auth.user.id);

        const activeSubscribers = isFollowing
          ? actionItemsSubscribers.filter(
              (subscriber) => subscriber !== auth.user.id,
            )
          : [...actionItemsSubscribers, auth.user.id];

        updatedActionItems[updatedItemIndex] = {
          ...updatedActionItems[updatedItemIndex],
          subscribers: activeSubscribers,
        };

        const item = updatedActionItems[updatedItemIndex];

        if (item.firebaseId) {
          handleChange(updatedActionItems, '', item.key, item);

          updateFirebaseData(
            `action_items/${item.firebaseId}`,
            removeUndefinedFromObject(item),
          );
        } else {
          const snapshot = await get(
            ref(
              db,
              `${FIREBASE_BASE_URL}/${auth.user.workspace.id}/action_items`,
            ),
          );

          if (snapshot.exists()) {
            const data = snapshot.val();

            const actionItems = Object.keys(data).map((key) => ({
              ...data[key],
              firebaseId: key,
            }));

            const firebaseId = actionItems.find((_item) =>
              [_item.key, _item.id].includes(item.key),
            )?.firebaseId;

            if (firebaseId) {
              updateFirebaseData(
                `action_items/${firebaseId}`,
                removeUndefinedFromObject(item),
              );

              handleChange(updatedActionItems, '', item.key, {
                ...item,
                firebaseId,
              });
            }
          }
        }
      },
      [actionItems, auth.user.id, auth.user.workspace.id, handleChange],
    );

    const isSectionFollowed = useMemo(() => followers.includes(auth.user.id), [
      auth.user.id,
      followers,
    ]);

    const isUrlValid = useMemo(
      () => !!urlFormData.url && validator.isURL(urlFormData.url),
      [urlFormData.url],
    );

    const canEdit = useMemo(() => !!sectionSlug, [sectionSlug]);

    const firebaseId = useMemo(
      () =>
        computeSections?.find((section) => section.slug === sectionSlug)?.key,
      [computeSections, sectionSlug],
    );

    const memoizedDropdownWrapperStyle = useMemo(
      () => ({
        right: '0px',
      }),
      [],
    );

    const memoizedDropdownMenu = useCallback(
      (handleClose: () => void) => (
        <>
          {canEdit && (
            <>
              <DropdownItem
                onClick={() => {
                  handleClose();
                  setRenameSectionModal(true);
                }}>
                Rename
              </DropdownItem>

              <DropdownItem
                onClick={() => {
                  handleClose();
                  setShowDeleteModal(true);
                }}>
                Delete
              </DropdownItem>
            </>
          )}
        </>
      ),
      [canEdit, setRenameSectionModal, setShowDeleteModal],
    );

    const memoizedDropdownIcon = useMemo(() => <MoreVerticalIcon />, []);

    const memoizedAction = useMemo(
      () => (
        <FlexRow className="gap-3">
          <DropdownVertical
            collapseOnClick
            dropdownWrapperStyle={memoizedDropdownWrapperStyle}
            menu={memoizedDropdownMenu}
            customIcon={memoizedDropdownIcon}
          />
        </FlexRow>
      ),
      [
        memoizedDropdownWrapperStyle,
        memoizedDropdownMenu,
        memoizedDropdownIcon,
      ],
    );

    const memoizedCustomButton = useMemo(
      () => (
        <Button
          kind="secondary"
          width="full"
          onClick={() => handleDeleteSection(firebaseId)}
          type="button">
          Delete
        </Button>
      ),
      [handleDeleteSection, firebaseId],
    );

    const memoizedDescription = useMemo(
      () => (
        <div>
          <Body2 align="center">
            Are you sure you want to delete{' '}
            <span className="text-[#585ADF] font-medium text-[16px]">
              {name}
            </span>
            ? This action cannot be undone.
          </Body2>
        </div>
      ),
      [name],
    );

    const memoizedUsers = useMemo(
      () =>
        users.map((user) => ({
          label: userName(user),
          value: user.id,
          avatar: user.avatar,
        })),
      [users],
    );

    const memoizedDropdownComponents = useCallback(
      (fieldId: string) =>
        !getActionItem(fieldId)?.source
          ? [
              {
                content: `Link with a goal`,
                id: 'goal',
              },
              {
                content: `Add a URL`,
                id: 'url',
              },
            ]
          : [
              {
                content: `Remove link`,
                id: 'remove-link',
              },
            ],
      [getActionItem],
    );

    const memoizedValue = useMemo(() => actionItems, [actionItems]);

    const memoizedHandleCloseAddUrl = useCallback(() => setShowAddUrl(''), []);

    const memoizedHandleCloseRenameSectionModal = useCallback(
      () => setRenameSectionModal(false),
      [setRenameSectionModal],
    );

    return (
      <SectionMenu
        title={name}
        action={memoizedAction}
        count={actionItems.filter((item) => Boolean(item.text)).length}
        style={useMemo(
          () => ({background: 'white', borderColor: '#E1E1EA'}),
          [],
        )}>
        <div className="p-6">
          <ActionItems
            showSource
            sortBy={sortBy}
            section={sectionSlug}
            onDueDateChange={useCallback(
              (fieldId, date, prevDueDate) => {
                const text = getActionItem(fieldId)?.text;

                if (date)
                  handleActionItemNotification(
                    fieldId,
                    `_(${userName(auth.user)})_  ${
                      !prevDueDate && date ? 'added a' : 'update the'
                    } due date of ${dayjs(date).format(
                      'ddd. DD MMM. YYYY',
                    )} to _(${text})_`,
                  );
              },
              [getActionItem, handleActionItemNotification, auth.user],
            )}
            onActivityLog={useCallback(
              ({type, message, actionItem}) => {
                if (type === 'create' && isSectionFollowed) {
                  handleActionItemNotification(
                    actionItem,
                    message.replace(
                      `[${auth.user.id}]`,
                      `_(${userName(auth.user)})_`,
                    ),
                  );
                }

                if (['complete'].includes(type)) {
                  handleActionItemNotification(
                    actionItem,
                    message.replace(
                      `[${auth.user.id}]`,
                      `_(${userName(auth.user)})_`,
                    ),
                  );
                }

                updateActionItemsActivities({
                  action: message,
                  createdBy: auth.user.id,
                  section: sectionSlug,
                  actionItem,
                  createdAt: dayjs().format(),
                });
              },
              [
                handleActionItemNotification,
                isSectionFollowed,
                auth.user,
                sectionSlug,
              ],
            )}
            onDrag={useCallback(() => {
              updateActionItemsFilter(selectedUser, {
                type: 'custom',
              });
            }, [selectedUser])}
            handleOnRemove={useCallback(
              (fieldId) => {
                const text = getActionItem(fieldId)?.text;

                handleActionItemNotification(
                  fieldId,
                  `_(${userName(auth.user)})_  deleted _(${text})_`,
                );
              },
              [getActionItem, handleActionItemNotification, auth.user],
            )}
            handleViewSource={useCallback(
              (data) => (data.source !== 'url' ? setViewSource(data) : null),
              [setViewSource],
            )}
            onHandleDropdownClick={useCallback(
              (fieldId, id) => {
                if (id === 'follow') {
                  handleFollowActionItems(fieldId);
                }
                if (id === 'url') {
                  setShowAddUrl(fieldId);
                }
              },
              [handleFollowActionItems],
            )}
            handleLinkGoal={useCallback(
              (fieldId, goalId) => {
                const updatedItemIndex = data.findIndex(
                  (item) => item.key === fieldId,
                );

                const updatedData = [...data];

                updatedData[updatedItemIndex] = {
                  ...updatedData[updatedItemIndex],
                  source: 'goal',
                  sourceId: goalId,
                };

                const updatedItem = updatedData[updatedItemIndex];

                if (goalId)
                  handleActionItemNotification(
                    fieldId,
                    `_(${userName(auth.user)})_ added a goal link to _(${
                      updatedItem.text
                    })_`,
                  );

                handleChange(updatedData, '', fieldId, updatedItem);

                refetchSources && refetchSources();
              },
              [
                data,
                handleActionItemNotification,
                auth.user,
                handleChange,
                refetchSources,
              ],
            )}
            goalOptions={goalOptions}
            canDelete={useCallback(
              (fieldId) => {
                const task = actionItems.find((task) => task.key === fieldId);

                if (task) {
                  // allow admin delete action item
                  if (auth.user.role === 'admin' && !!task) {
                    return true;
                  }

                  // allow manager of task users delete task
                  const usersInvolved = users
                    .filter((user) =>
                      [task?.user, task?.updatedBy, task?.assignee].includes(
                        user?.id,
                      ),
                    )
                    .map((user) => user.reviewer?.id);

                  if (usersInvolved.includes(auth.user?.id)) {
                    return true;
                  }
                }
                return false;
              },
              [actionItems, users, auth.user.id, auth.user.role],
            )}
            users={memoizedUsers}
            dropdownComponents={memoizedDropdownComponents}
            handleChange={handleActionItemChange as any}
            source=""
            sourceId={'general'}
            value={memoizedValue}
            userId={auth.user.id}
          />
        </div>

        <Modal open={!!showAddUrl} onClose={memoizedHandleCloseAddUrl}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={memoizedHandleCloseAddUrl}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Add URL </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={urlFormData.title}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(value) =>
                    handleUpdateUrlFormData('title', value.target.value)
                  }
                  name="name"
                />

                <TextField
                  label="Add URL"
                  value={urlFormData.url}
                  state={isUrlValid ? 'default' : 'error'}
                  helper={isUrlValid ? '' : 'Invalid url'}
                  placeholder="Paste link (use https://)"
                  onChange={(value) =>
                    handleUpdateUrlFormData('url', value.target.value)
                  }
                  name="url"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() => handleAddUrl(showAddUrl)}
                  data-form-action={true}
                  disabled={!isUrlValid || !urlFormData.title}>
                  Apply
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
        <Modal
          open={showRenameSectionModal}
          onClose={memoizedHandleCloseRenameSectionModal}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={memoizedHandleCloseRenameSectionModal}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>Rename section </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={sectionTitle}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(event) => setSectionTitle(event.target.value)}
                  name="name"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() =>
                    handleEditSection(firebaseId || '', sectionTitle).then(
                      () => {
                        setSectionTitle('');
                        setRenameSectionModal(false);
                      },
                    )
                  }
                  data-form-action={true}
                  disabled={!sectionTitle || sectionTitle.length > 25}>
                  Create
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>

        <CustomIconModal
          open={showDeleteModal}
          onClose={useCallback(() => setShowDeleteModal(false), [
            setShowDeleteModal,
          ])}
          customButton={memoizedCustomButton}
          body={<></>}
          onClick={() => {}}
          title={'Delete Section?'}
          description={memoizedDescription}
        />
      </SectionMenu>
    );
  },
);
