import React, {FC, useCallback, useMemo} from 'react';
import {ObjSummary} from '../../reporting-pages/reporting-overview/obj-summary';
import {Insight} from '../interface';
import {Body1, Body2} from '@ui/atoms/typography';
import {VerticalInsightConfig} from '../../dashboard/insight-config';
import {getInitialAdditionalFilters} from '../edit-insight/edit-insight-filter';
import {AnalyticsController} from '../../reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../../reporting-pages/admin-analytics/admin-analytics.request';
import {useQuery} from 'react-query';
import {FlexRowCenter} from '@ui/style/styles';
import {ItemLoader} from '@ui/organisms/item-loader';
import {formatConfigDate} from '../insight-options';
import {Flash2Icon} from '@ui/atoms/icons/flash-icon';

interface ObjectiveStatusRateProps extends Insight {
  showRangeType?: boolean;
  overallDateRange?: null | {startDate: string; endDate: string};
}

export const ObjectiveStatus: FC<ObjectiveStatusRateProps> = ({
  showRangeType,
  name,
  config,
  overallDateRange,
}) => {
  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );

  const formatDateRange = useMemo(
    () =>
      overallDateRange ? overallDateRange : formatConfigDate(config) || {},
    [config, overallDateRange],
  );

  const fetchSpecifiedGoals = useCallback(
    async (filters: any, view?: string) => {
      const request = new AnalyticsRequestImpl();
      const controller = new AnalyticsController(request);

      const specifiedGoalsResponse = await controller.fetchSpecifiedGoals(
        filters,
        view,
      );

      return specifiedGoalsResponse;
    },
    [],
  );

  const {data, isLoading} = useQuery(['objective-status', config], () =>
    fetchSpecifiedGoals({
      startDate: formatDateRange.startDate,
      endDate: formatDateRange.endDate,
      newReport: true,
      goalType: config.objectiveType === 'all' ? '' : config.objectiveType,
      isKpi: false,
      department: config.groups?.join(','),
    }),
  );

  if (!config.objectiveType) {
    return (
      <FlexRowCenter className="min-h-[180px]">
        <div className="bg-backgroundDark px-6 py-2 rounded-[10px] ">
          <Body2 kind="textBody">
            Select an option to show performance insights.
          </Body2>
        </div>
      </FlexRowCenter>
    );
  }

  return (
    <div className="bg-white shadow-sm p-4 md:p-6 h-full border rounded-[10px] border-borderLight min-h-[150px] w-full">
      <Body1 weight="bold" className="mb-2 text-base md:text-lg">
        {name}
      </Body1>

      {showRangeType && (
        <div className="w-full overflow-x-auto">
          <div className="min-w-[300px]">
            <div className="flex items-center gap-2">
              {Boolean(overallDateRange) && <Flash2Icon />}

              <VerticalInsightConfig
                config={config}
                additionalFilters={additionalFilters}
              />
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <FlexRowCenter className="w-full py-4 md:py-6">
          <ItemLoader />
        </FlexRowCenter>
      ) : (
        <div className="w-full overflow-x-auto">
          <div className="min-w-full">
            <ObjSummary
              showCards={false}
              data={data?.summary}
              avgTotal={`${data?.summary.goals.fulfillment || 0}%`}
              title={'Completion'}
            />
          </div>
        </div>
      )}
    </div>
  );
};
