import styled from 'styled-components';
import dayjs from 'dayjs';
import {useDropDown} from '../../../hooks';
import {DateInput} from '../../atoms/date-input';
import {Calendar} from '../calendar';
import {ChangeEvent, CSSProperties, memo, useCallback, useState} from 'react';

const Wrapper = styled.div`
  position: relative;
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  display: inline-block;
  top: 130%;
  left: 0;
  z-index: 3;
`;

export interface DatePickerProps {
  label: string;
  value?: string;
  placeholder?: string;
  onChange?: (date: string) => void;
  disabledBefore?: string;
  inputStyle?: CSSProperties;
  dateAlign?: 'left' | 'right';
  disabledAfter?: string;
  disabled?: boolean;
}

export const DatePicker = memo(function ({
  onChange,
  label,
  value,
  placeholder,
  inputStyle,
  disabledBefore,
  dateAlign,
  disabledAfter,
  disabled,
}: DatePickerProps) {
  const [date, setDate] = useState<dayjs.Dayjs | null>(
    value ? dayjs(value) : null,
  );

  const [inputValue, setInputValue] = useState(
    value ? dayjs(value).format('DD/MM/YYYY') : '',
  );

  const [error, setError] = useState<string | undefined>();

  const {handleOpen, ref, open, handleClose} = useDropDown();

  const onDatePicked = useCallback(
    (selectedDate: Date) => {
      handleClose();
      const newDate = dayjs(selectedDate);
      setDate(newDate);
      setInputValue(newDate.format('DD/MM/YYYY'));
      setError(undefined);
      if (onChange) {
        onChange(newDate.format());
      }
    },
    [handleClose, onChange],
  );

  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputText = e.target.value;
      setInputValue(inputText);

      const result = parseDate(inputText);

      if (result.isValid && result.date) {
        setDate(result.date);
        setError(undefined);
        if (onChange) {
          onChange(result.date.format());
        }
      } else {
        setDate(null);
        if (result.error) {
          setError(result.error);
        }
      }
    },
    [onChange],
  );

  return (
    <Wrapper ref={ref}>
      <DateInput
        label={label}
        onClick={handleOpen}
        value={inputValue}
        onInputChange={handleInputChange}
        inputStyle={inputStyle}
        dateAlign={dateAlign}
        placeholder={placeholder}
        active={open}
        disabled={disabled}
        state={error ? 'error' : undefined}
      />

      {open && (
        <DropdownWrapper>
          <Calendar
            onChange={onDatePicked}
            value={date?.format() || ''}
            disabledBefore={disabledBefore}
            disabledAfter={disabledAfter}
          />
        </DropdownWrapper>
      )}
    </Wrapper>
  );
});

DatePicker.displayName = 'DatePicker';

const DATE_FORMATS = [
  'DD/MM/YYYY',
  'D/M/YYYY',
  'DDMMYYYY',
  'DD-MM-YYYY',
  'D/MM/YYYY',
];

interface ParsedDate {
  isValid: boolean;
  date: dayjs.Dayjs | null;
  error?: string;
}

const parseDate = (value: string): ParsedDate => {
  // If empty, return valid but null
  if (!value) {
    return {isValid: true, date: null};
  }

  // Try each date format
  let parsedDate: dayjs.Dayjs | null = null;

  for (const format of DATE_FORMATS) {
    const parsed = dayjs(value, format);
    if (parsed.isValid()) {
      parsedDate = parsed;
      break;
    }
  }

  if (!parsedDate) {
    return {
      isValid: false,
      date: null,
      error: 'Invalid date format. Use DD/MM/YYYY',
    };
  }

  return {isValid: true, date: parsedDate};
};
