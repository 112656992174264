import {Button} from '@ui/atoms/button';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {Headline4, Body2, Headline2, Body1} from '@ui/atoms/typography';
import {SectionCard} from '@ui/layouts/section-card';
import {checkinName} from '@utils/checkin-name';
import {useCallback, useMemo, useState} from 'react';
import {SectionComponentWrapper} from '../admin-checkins.styles';
import {useBreakpoints} from '@utils/use-breakpoints';
import {ModalCard} from '@ui/layouts/modal-card';
import {Modal} from '@ui/molecules/modal';
import {ModalWrapper} from '../../../admin-workspace-styled';
import {CancelIcon} from '@ui/atoms/icons';
import {BackAction} from '@ui/molecules/back-action';
import {CompanyCheckinsController} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.controller';
import {CompanyCheckinsRequestImpl} from '@pages/dashboard/company-legacy/company-components/company-checkins/company-checkins.request';
import {useQuery} from 'react-query';
import {getWeekOfYear, getWeekRange} from '@utils/date';
import dayjs from 'dayjs';
import {checkinResetTracker} from '@utils/firebase-request';
import {useFirebaseFetch} from '@hooks/query-hook';

interface ResetCheckinsProps {
  resetPendingChanges: () => void;
}
export const ResetCheckins = ({resetPendingChanges}: ResetCheckinsProps) => {
  const {isXs} = useBreakpoints();

  const [showConfirmResetModal, setShowConfirmResetModal] = useState(false);

  const checkinsRequest = useMemo(() => new CompanyCheckinsRequestImpl(), []);

  const checkinsController = useMemo(
    () => new CompanyCheckinsController(checkinsRequest),
    [checkinsRequest],
  );

  const initWorkspaceCheckins = useCallback(async () => {
    const response = await checkinsController.getCurrentCheckinsInfo();

    if (response) {
      const weekOfYear = getWeekOfYear();

      const year = new Date().getFullYear();
      const range = getWeekRange(
        response.weekOfYear || weekOfYear,

        response.year || year,
      );

      return range;
    }
    return {
      starts: '',
      ends: '',
    };
  }, [checkinsController]);

  const {data: workspaceData} = useQuery(
    ['initWorkspaceCheckins'],
    () => initWorkspaceCheckins(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const weekRange = useMemo(
    () =>
      `${dayjs(workspaceData?.starts).format('MMMM DD')} -  ${dayjs(
        workspaceData?.ends,
      ).format('MMMM DD')}`,
    [workspaceData?.ends, workspaceData?.starts],
  );

  const [isResetting, setIsResetting] = useState(false);

  const {data: resetTracker} = useFirebaseFetch(`checkin/reset`);

  const lastResetDate = useMemo(
    () =>
      resetTracker
        ? dayjs(resetTracker.timestamp).format('MMM. DD, YYYY')
        : '-',
    [resetTracker],
  );
  const handleReset = async () => {
    setIsResetting(true);
    const response = await checkinsController.resetCurrentCheckin();

    if (response) {
      setShowConfirmResetModal(false);

      resetPendingChanges();

      checkinResetTracker();
    }
    setIsResetting(false);
  };

  return (
    <div>
      <SectionCard
        title="Reset Check-ins"
        headerPadding={isXs ? 'small' : undefined}
        contentStyling={{
          padding: isXs ? '0px' : '0px 0px 10px 0px',
        }}
        CustomHeaderTitle={
          <div>
            <Headline4 kind="textDark" className="capitalize">
              Reset {checkinName()}s
            </Headline4>
            <Body2 kind="textBody">
              Apply changes to
              {checkinName()}
            </Body2>
          </div>
        }>
        <SectionComponentWrapper>
          <Body2>
            Changes to check-in settings or newly added questions typically
            apply from the next check-in cycle. However, you can implement these
            modifications immediately by clicking the "Reset Check-in" button
            below.
          </Body2>

          <Body2 className="mt-4">
            <span className="font-[700]">Important:</span>  Resetting will
            discard any submissions for the current period{' '}
            <span className="text-textBody">({weekRange})</span>.
          </Body2>

          <Button
            style={{padding: '8px 16px'}}
            type="button"
            disabled={!workspaceData?.starts}
            onClick={() => {
              setShowConfirmResetModal(true);
            }}
            className="mt-3">
            Reset Check-in
          </Button>
          <VerticalSpacer size="24px" />

          <Body2 kind="textBody">Last reset: {lastResetDate}</Body2>
        </SectionComponentWrapper>
      </SectionCard>

      <>
        <Modal
          open={showConfirmResetModal}
          onClose={() => setShowConfirmResetModal(false)}>
          <ModalWrapper>
            <div className="flex justify-end mb-3">
              <BackAction
                icon={<CancelIcon />}
                title="Close"
                type="button"
                onClick={() => setShowConfirmResetModal(false)}
                width="md"
              />
            </div>

            <ModalCard title="">
              <div>
                <Headline2 align="center">Confirm Check-in Reset</Headline2>
                <Body1 kind="textBody" align="center" className="mt-2">
                  Are you sure you want to reset check-ins?
                </Body1>
                <Body1 kind="textBody" align="center" className="mt-3">
                  This will apply recent changes immediately and delete all
                  submitted responses for the current period ({weekRange}). This
                  action cannot be undone.
                </Body1>

                <Button
                  width="full"
                  onClick={handleReset}
                  isLoading={isResetting}
                  className="mt-6">
                  Reset Check-ins
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>
      </>
    </div>
  );
};
