import {memo, useMemo, useState, useCallback} from 'react';
import {FlexRow, FlexRowSpaceBetween} from '@ui/style/styles';
import {Body1, Body2, Headline2} from '@ui/atoms/typography';
import {useActionItemsWithSortFilter} from '@hooks/action-items';
import {useQuery} from 'react-query';
import {useStoreContext} from '@store/store-context';
import {userName} from '@utils/user-name';
import {ModalWrapper} from '../groups/styles';
import {Button} from '@ui/atoms/button';
import {ViewActionItemsSource} from './view-source';
import {Modal} from '@ui/molecules/modal';
import {BackAction} from '@ui/molecules/back-action';
import {CancelIcon} from '@ui/atoms/icons';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {ModalCard} from '@ui/layouts/modal-card';
import {TextField} from '@ui/molecules/field/textfield';
import {SecondaryPlusIcon} from '@ui/atoms/icons/plus';
import {HorizontalSideRule} from '@ui/atoms/spacer/spacer';
import {ItemLoader} from '@ui/organisms/item-loader';
import {ActionItemsFilter, ActionItemsSortByFilter} from './filter';
import {ActionType} from '@api/action-items-api/action-items-request';
import {ActionItemActivities} from './activities';
import {ActionItemsSections} from './action-items-sections';

// Function to find and replace the item if it exists
const findAndReplaceTask = (tasks: any[], item: ActionType) => {
  return tasks.map((task) => {
    if (task.key === item.key) {
      return item;
    }

    return task;
  });
};

export const computeSubscribers = (data: any) => {
  const assignee = data?.assignee;
  const assigneeId =
    typeof assignee === 'string' ? assignee : assignee?.id || null;

  const assignedIsSubscribed = (data.subscribers?.length
    ? data.subscribers
    : [assigneeId]
  ).find((_subscriber: string) => _subscriber === assigneeId);

  const currentSubscribed = data.subscribers
    ? data.subscribers
    : assigneeId
    ? [assigneeId]
    : [];

  return assignedIsSubscribed
    ? currentSubscribed
    : [...currentSubscribed, assigneeId];
};

export const updateTasks = (
  prevTasks: ActionType[],
  users: any[],
  data: ActionType[],
  action?: 'remove' | 're-sort',
  fieldId?: string,
  item?: ActionType,
) => {
  if (action === 'remove') {
    return prevTasks.filter((task) => task.key !== fieldId);
  }

  if (action === 're-sort') {
    const dataMap = data.reduce((acc, item, index) => {
      acc[item.key] = {
        order: index + 1,
        section: item.section,
        section_slug: item.section_slug,
      };
      return acc;
    }, {} as Record<string, {order: number; section: string; section_slug: string}>);

    return prevTasks.map((task) => ({
      ...task,
      order: dataMap[task.id]?.order || task.order,
      section: dataMap[task.id]?.section || task.section,
      section_slug: dataMap[task.id]?.section_slug || task.section_slug,
    }));
  }

  if (!item || !item.text) return prevTasks;

  const itemExists = prevTasks.find((task) => task.key === item.key);

  if (typeof item.assignee === 'string') {
    item.assignee = users.find((user) => user.id === item.assignee);
  }

  const subscribers = computeSubscribers({
    assignee: item.assignee,
    subscribers: item.subscribers,
  });

  const updatedItem = {
    ...item,
    subscribers,
  };

  if (action === 'remove-link') {
    updatedItem.sourceTitle = undefined;
    updatedItem.sourceId = undefined;
  }

  if (itemExists) {
    return findAndReplaceTask(prevTasks, updatedItem);
  }

  return [...prevTasks, updatedItem];
};

export const ActionItemsPage = memo(() => {
  const {
    usersStore: {users},
    authStore: {auth},
  } = useStoreContext();

  const [viewSource, setViewSource] = useState({
    id: '',
    source: '',
  });

  const [userFilter, setUserFilter] = useState(auth.user.id);

  const [showAddSectionModal, setShowAddSectionModal] = useState(false);

  const selectedUser = userFilter;

  const getManager =
    users.find((user) => user?.id === selectedUser)?.reviewer?.id || '';

  const selectedManager = getManager;

  useQuery(['goals', selectedUser], () => initGoals(1, 'active'));

  const {
    handleSource,
    handleChange,
    goalOptions,
    isLoading,
    initGoals,
    computeSections,
    sources,
    firebaseActionItems: data,
    showCompleted,
    setTasks,
    isCustomFilter,
    sortBy,
    updateShowCompleted,
    completedTasks,
    sortedData,
    setSectionTitle,
    canCreateSection,
    findSectionSlug,
    handleCreateSection,
    findSectionFollowers,
    sectionTitle,
    groupBySections,
    setSortBy,
  } = useActionItemsWithSortFilter(selectedManager, selectedUser);

  const {data: actionItemsSources, refetch: refetchSources} = useQuery(
    ['sources', selectedUser, JSON.stringify(data)],
    handleSource,
  );

  useMemo(() => {
    if (actionItemsSources)
      setTasks((prevTasks) =>
        prevTasks.map((task) => {
          const update = actionItemsSources.get(task.id);

          return update ? {...task, ...update} : task;
        }),
      );
  }, [actionItemsSources, setTasks]);

  const getSourceDetail = useCallback(
    (sourceId: string) => {
      return sources.get(sourceId);
    },
    [sources],
  );

  const [selectedSection, setSectionSelection] = useState('');

  const actionItems = useMemo(() => {
    return selectedSection
      ? groupBySections.filter(([key]) => key === selectedSection)
      : groupBySections;
  }, [selectedSection, groupBySections]);

  const sourceItem = useMemo(
    () =>
      viewSource.source
        ? viewSource.source === 'goal'
          ? (getSourceDetail(viewSource.id) as any)?.goal
          : (getSourceDetail(viewSource.id) as any)?.meeting
        : undefined,
    [getSourceDetail, viewSource.id, viewSource.source],
  );

  const [showActivities, setShowActivities] = useState(false);

  const handleCloseModal = useCallback(() => {
    setShowAddSectionModal(false);
  }, []);

  const handleUserFilterChange = useCallback((value) => {
    setUserFilter(value);
  }, []);

  const toggleActivities = useCallback(() => {
    setShowActivities(false);
  }, []);

  const handleActionItemsChange = useCallback(
    (data: any, action, fieldId, item: any) => {
      if (action !== 'add-url') {
        handleChange(data, action, fieldId, item);
      }

      setTasks((prev) =>
        updateTasks(prev, users, data, action as any, fieldId, item),
      );
    },
    [handleChange, setTasks, users],
  );

  return (
    <>
      <FlexRowSpaceBetween className="mb-6">
        <div className="">
          <Headline2>Action Items</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Create and manage priority lists, set due dates, track progress,
              and mark tasks complete.
            </Body1>
          </div>
        </div>
      </FlexRowSpaceBetween>

      <div className="flex items-row gap-6">
        <div className="w-full">
          <div className="bg-white rounded-[10px] border border-borderLight px-6 py-4">
            <div className="flex sm:items-start items-center justify-between sm:flex-col flex-row">
              <ActionItemsFilter
                onChange={handleUserFilterChange}
                value={{users: selectedUser}}
              />
              <FlexRow className="sm:mt-2 mt-0">
                <ActionItemsSortByFilter
                  sortBy={sortBy}
                  updateSortBy={setSortBy}
                  isCustomFilter={isCustomFilter}
                  userId={selectedUser}
                />
                <div className="mx-1">
                  <HorizontalSideRule size="16px" />
                </div>
                <Body2 kind="textBody" className="whitespace-nowrap">
                  {completedTasks.length} completed
                </Body2>
                <div className="mx-1">
                  <HorizontalSideRule size="16px" />
                </div>
                <button
                  onClick={() => {
                    updateShowCompleted(!showCompleted);
                  }}>
                  <Body2 weight="bold" kind="purple300">
                    {showCompleted ? 'Hide' : 'Show'}
                  </Body2>
                </button>
              </FlexRow>
            </div>

            {canCreateSection ? (
              <FlexRow className="gap-2 pt-3 border-t mt-3 border-t-[#EDEDF2] flex-wrap">
                <button
                  onClick={() => setSectionSelection('')}
                  className={`py-1 px-2 ${
                    selectedSection ? 'bg-[#F6F6F8]' : 'bg-[#585ADF]'
                  }  rounded-lg flex items-center gap-1 cursor-pointer`}>
                  <Body2 kind={selectedSection ? 'textBody' : 'white'}>
                    All
                  </Body2>
                  <div className="border border-borderLight bg-white rounded-md py-[3px] px-[6px]">
                    <Body2 size="9px" weight="bold">
                      {sortedData.length}
                    </Body2>
                  </div>
                </button>
                {computeSections.map((section) => (
                  <button
                    onClick={() => setSectionSelection(section.name)}
                    className={`py-1 px-2 ${
                      selectedSection !== section.name
                        ? 'bg-[#F6F6F8]'
                        : 'bg-[#585ADF]'
                    } rounded-lg flex items-center gap-1 cursor-pointer`}
                    key={section.slug}>
                    <Body2
                      kind={
                        selectedSection !== section.name ? 'textBody' : 'white'
                      }>
                      {section.name}
                    </Body2>
                  </button>
                ))}
                <button
                  className="py-1 px-2 border border-[#CECEDE] rounded-lg flex items-center gap-1"
                  onClick={() => setShowAddSectionModal(true)}>
                  <SecondaryPlusIcon stroke="#5F5F8C" />
                  <Body2 weight="bold" kind="textBody">
                    New Section
                  </Body2>
                </button>
              </FlexRow>
            ) : null}
          </div>

          <div className="mt-4">
            {isLoading && <ItemLoader />}

            {actionItems &&
              !isLoading &&
              (actionItems as any).map(([key, actionItems]: any) => (
                <div className="mb-4" key={key}>
                  <ActionItemsSections
                    actionItems={actionItems}
                    data={data}
                    refetchSources={refetchSources}
                    sortBy={isCustomFilter ? 'order' : sortBy}
                    setViewSource={setViewSource}
                    sources={sources}
                    name={key}
                    sectionSlug={findSectionSlug(key) || ''}
                    followers={findSectionFollowers(key)}
                    getSourceDetail={getSourceDetail}
                    handleChange={handleActionItemsChange}
                    goalOptions={goalOptions}
                    selectedUser={selectedUser}
                  />
                </div>
              ))}
          </div>
        </div>

        <ActionItemActivities
          showActivities={showActivities}
          toggleActivities={toggleActivities}
        />
        <Modal open={showAddSectionModal} onClose={handleCloseModal}>
          <ModalWrapper>
            <BackAction
              icon={<CancelIcon />}
              title="Close"
              onClick={handleCloseModal}
              width="md"
            />
            <VerticalSpacer size="16px" />
            <ModalCard title="">
              <div>
                <Headline2>New section </Headline2>

                <VerticalSpacer size="20px" />
                <TextField
                  maxLength={25}
                  label="Title"
                  value={sectionTitle}
                  placeholder="Enter title (max 25 characters)"
                  onChange={(event) => setSectionTitle(event.target.value)}
                  name="name"
                />

                <VerticalSpacer size="14px" />

                <Button
                  kind="primary"
                  width="full"
                  onClick={() =>
                    handleCreateSection().then(() => {
                      setSectionTitle('');
                      setShowAddSectionModal(false);
                    })
                  }
                  data-form-action={true}
                  disabled={!sectionTitle || sectionTitle.length > 25}>
                  Create
                </Button>
              </div>
            </ModalCard>
          </ModalWrapper>
        </Modal>

        {!!viewSource.id && (
          <ViewActionItemsSource
            userName={userName(users.find((user) => user.id === selectedUser))}
            onClose={() => setViewSource({id: '', source: ''})}
            type={viewSource.source}
            goal={sourceItem}
            meeting={sourceItem}
          />
        )}
      </div>
    </>
  );
});
