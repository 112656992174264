import {useCallback, useMemo, useState} from 'react';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {IMeetingResponse} from '@hooks';
import {authStore} from '@store/stores/auth-store';
import {get} from 'idb-keyval';
import {parseRecurrenceRule} from '@utils/recurrence';
import {IAPIUserFormat} from '@hooks';

export const useMeetingFetch = (
  getCalendarEventById: Function,
  isSigned: boolean,
) => {
  const request = useMemo(() => new MeetingRequestImpl(), []);
  const controller = useMemo(() => new MeetingController(request), [request]);

  const [agendas, setAgendas] = useState({host: [], participant: []});

  const fetchMeetingDetails = useCallback(
    async (
      id: string,
      startMeeting?: boolean,
    ): Promise<
      | (IMeetingResponse & {
          disableActionItems?: boolean;
          primaryMeeting?: {
            title: string;
            connectedCalendar: any[];
            description: string;
            id: string;
            meetingLink: string;
            enableBeamAI: boolean;
          };
          recurrence?: {
            pattern: string;
            frequency: string;
            interval?: number;
            until?: string;
            count?: number;
          };
        })
      | false
    > => {
      const users = await get(`${authStore.auth.user.workspace.id}/users`);
      const response = await controller.getMeeting(id, startMeeting);

      if (!response) return false;

      const primaryDetails = {
        title: response.title,
        description: response.description,
        enableBeamAI: response.enableBeamAI,
        id: response.id,
        meetingLink: response.meetingLink,
        connectedCalendar: response.connectedCalendar,
        startDate: response.startDate,
        time: response.time,
      };

      // Handle primary meeting logic
      if (response.connectedCalendar) {
        const primaryMeeting = response.connectedCalendar.find(
          (calendar: {isPrimary: boolean}) => calendar.isPrimary,
        );

        if (primaryMeeting) {
          if (primaryMeeting.calendarId !== response.calendarId) {
            const _response_ = await controller.getMeetings({
              calendarId: primaryMeeting?.calendarId,
              startDate: primaryMeeting?.startDate,
            });

            if (_response_) {
              const item = _response_.results?.[0];
              if (item) {
                response.primaryMeeting = {
                  title: item.title,
                  description: item.description,
                  enableBeamAI: item.enableBeamAI,
                  id: item.id,
                  connectedCalendar: item.connectedCalendar,
                  meetingLink: item.meetingLink,
                  startDate: _response_.startDate,
                  time: _response_.time,
                };
              }
            }
          } else {
            response.primaryMeeting = primaryDetails;
          }
        } else {
          response.connectedCalendar = [
            {
              isPrimary: true,
              meetingId: response.id,
              calendarId: response.calendarId,
              startDate: response.startDate,
              title: response.title,
            },
          ];
          response.primaryMeeting = primaryDetails;
        }
      }

      // Process users and participants
      const _response = {
        ...response,
        user: users.find((user: IAPIUserFormat) => user.id === response.user),
        participant: response.participant.map((participant: any) =>
          typeof participant === 'string'
            ? users.find((user: IAPIUserFormat) => user.id === participant) ||
              participant
            : participant,
        ),
        time: !response.time?.toLowerCase().includes('invalid')
          ? response.time
          : '',
      };

      // Handle calendar event details if signed in
      if (response?.calendarId && isSigned) {
        try {
          const resp = await getCalendarEventById(
            response?.calendarId,
            'primary',
          );
          const event = resp.result;

          if (event) {
            // Process recurrence rules
            if (event.recurrence) {
              _response.recurrence = parseRecurrenceRule(event.recurrence[0]);
            } else if (event.recurringEventId) {
              _response.recurrence = {
                isRecurringInstance: true,
                recurringEventId: event.recurringEventId,
                pattern: 'instance',
                frequency: 'instance',
              };

              // Fetch main recurring event details
              try {
                const mainEvent = await getCalendarEventById(
                  event.recurringEventId,
                  'primary',
                );

                if (mainEvent?.result?.recurrence) {
                  const mainRecurrence = parseRecurrenceRule(
                    mainEvent.result.recurrence[0],
                  );
                  _response.recurrence = {
                    ..._response.recurrence,
                    ...mainRecurrence,
                    isRecurringInstance: true,
                    recurringEventId: event.recurringEventId,
                  };
                }
              } catch (error) {
                console.warn(
                  'Could not fetch main recurring event details',
                  error,
                );
              }
            }

            // Process attendees
            const allUsersEmail = users.map(
              (user: {email: string}) => user.email,
            );
            const isExistingParticipant = allUsersEmail.filter((user: string) =>
              response.participant.includes(user),
            );

            const attendees =
              event?.attendees?.filter(
                (attendee: {email: string}) =>
                  !isExistingParticipant.includes(attendee.email),
              ) || [];

            attendees.forEach((attendee: {email: string}) => {
              if (allUsersEmail.includes(attendee.email)) {
                const user = users.find(
                  (user: {email: string}) => user.email === attendee.email,
                );
                _response.participant.push(user);
              }
            });

            _response.extraParticipant = attendees;
            _response.meetingLink = event?.hangoutLink;
            if (_response.primaryMeeting) {
              _response.primaryMeeting.meetingLink = event?.hangoutLink;
            }
            _response.googleEventDetails = event;
          }
        } catch (error) {
          console.error('Error fetching calendar event details:', error);
        }
      }

      setAgendas({
        host: _response.hostAgenda,
        participant: _response.participantAgenda,
      });

      return _response;
    },
    [controller, getCalendarEventById, isSigned],
  );

  const fetchMeetingByCalendarId = useCallback(
    async (calendarId: string, startDate: string) => {
      const existingMeetings = await controller.getMeetings({
        calendarId,
        startDate,
      });

      if (existingMeetings) {
        const meeting = existingMeetings?.results[0];
        if (meeting) {
          return await fetchMeetingDetails(meeting.id);
        }
      }
    },
    [controller, fetchMeetingDetails],
  );

  return {
    fetchMeetingDetails,
    fetchMeetingByCalendarId,
    agendas,
    controller,
  };
};
