import React, {FC, memo, useCallback, useEffect, useMemo} from 'react';
import {Insight} from '../insights/interface';
import {AnalyticsController} from '../reporting-pages/admin-analytics/admin-analytics.controller';
import {AnalyticsRequestImpl} from '../reporting-pages/admin-analytics/admin-analytics.request';
import {useQueries} from 'react-query';
import {InsightCard, PercentageProgress} from './insight-card';
import {MeasurementType} from '@ui/interface';
import {
  comparisonConfigDate,
  formatConfigDate,
} from '../insights/insight-options';
import dayjs from 'dayjs';
import {VerticalInsightConfig} from './insight-config';
import {getInitialAdditionalFilters} from '../insights/edit-insight/edit-insight-filter';
import {Body1, Headline2} from '@ui/atoms/typography';
import {
  FlexRow,
  FlexRowCenter,
  FlexRowEnd,
  FlexRowSpaceBetween,
} from '@ui/style/styles';
import {MultiLineChart} from '../reporting-pages/admin-analytics/analytics-pages/analytics-status/Graph';
import {goalDueDate} from '@utils/date';
import {IGoalResult} from '@hooks';
import {HoverTooltip} from '@ui/molecules/hover-tooltip';
import {CandleVIcon} from '@ui/atoms/icons/candle-v';
import {ItemLoader} from '@ui/organisms/item-loader';
import {Flash2Icon} from '@ui/atoms/icons/flash-icon';
const commaNumber = require('comma-number');

interface KPIInsightProps extends Insight {
  showRangeType?: boolean;
  handleEditLayout?: (id: string) => void;
  handleName?: (id: string) => void;
  overallDateRange?: null | {startDate: string; endDate: string};
}

interface Activity {
  startDate: string;
  currentValue: number;
  percentageProgress: number;
  endDate: string;
  performance: 'at_risk' | 'on_track' | 'behind';
  prevValue?: number;
}

interface KPIResponse extends IGoalResult {
  activities: Activity[];
  firstValue: number;
  secondValue: number;
  percentageChange: number;
  difference: number;
}

export const KpiInsight: FC<KPIInsightProps> = memo((props) => {
  const {config, id, handleName, overallDateRange, ...rest} = props;

  const formatDate = useMemo(
    () =>
      overallDateRange ? overallDateRange : formatConfigDate(config) || {},
    [config, overallDateRange],
  );

  const comparisonDate = useMemo(
    () =>
      formatDate ? comparisonConfigDate(config, formatDate.startDate) : null,
    [config, formatDate],
  );

  const fetchGoalById = useCallback(async (): Promise<KPIResponse> => {
    if (!formatDate || !id) {
      return {} as KPIResponse;
    }

    const request = new AnalyticsRequestImpl();
    const controller = new AnalyticsController(request);
    const response = await controller.fetchReportGoalById(id);

    if (response) {
      const _response = await controller.fetchReportKPIComparison(
        id,
        response.measurement?.preference,
        formatDate,
        comparisonDate ? comparisonDate : formatDate,
      );

      return {
        ...response,
        ..._response,
      };
    }
    return response;
  }, [comparisonDate, formatDate, id]);

  const queries = useMemo(() => {
    const baseQuery = {
      queryKey: [
        'goal',
        id,
        formatDate.startDate,
        formatDate.endDate,
        comparisonDate,
      ],
      queryFn: () => fetchGoalById(),
      staleTime: 5 * 60 * 1000, // 5 minutes
      cacheTime: 10 * 60 * 1000, // 10 minutes
    };

    return [baseQuery];
  }, [
    id,
    formatDate.startDate,
    formatDate.endDate,
    comparisonDate,
    fetchGoalById,
  ]);

  const results = useQueries(queries);

  const [kpi] = results;

  const isLoading = kpi.isLoading;

  const measurement = useMemo(() => {
    if (kpi.data) {
      return kpi.data.measurement;
    }
    return;
  }, [kpi.data]);

  const comparisonValue = 0;

  const percentProgress = useMemo(() => {
    if (comparisonValue === null) return;

    return kpi.data?.percentageChange;
  }, [kpi.data?.percentageChange]);

  const currency =
    measurement?.unit.toUpperCase() === MeasurementType.CURRENCY
      ? measurement.symbol
      : '';

  const percentage =
    measurement?.unit.toUpperCase() === MeasurementType.PERCENTAGE ? '%' : '';

  const isChartType = config.view === 'chart';

  const additionalFilters = getInitialAdditionalFilters(
    config.list,
    config.users,
  );

  const value = `${currency}${commaNumber(
    kpi.data?.firstValue || 0,
  )}${percentage}`;

  const goal = kpi.data;

  useEffect(() => {
    if (goal?.name) handleName?.(goal.name);
  }, [goal?.name, handleName]);

  return (
    <div className="h-full w-full">
      {isChartType ? (
        <>
          <div className="bg-white flex justify-between flex-col items-start shadow-[0_3px_24px_0_rgba(208,208,221,0.16)] p-6 border h-full rounded-[10px] border-borderLight  min-h-[150px]">
            <Body1 weight="bold">{rest.name}</Body1>

            {rest.showRangeType && (
              <FlexRowSpaceBetween className="w-full">
                <div className="flex items-center gap-2">
                  {Boolean(overallDateRange) && (
                    <HoverTooltip tooltipText="Dashboard filters are applied to this report.">
                      <Flash2Icon />
                    </HoverTooltip>
                  )}

                  <VerticalInsightConfig
                    config={config}
                    overallDateRange={overallDateRange}
                    additionalFilters={additionalFilters}
                  />
                </div>

                <FlexRowEnd>
                  {rest.showRangeType && (
                    <button
                      onClick={() => {
                        if (rest.firebaseId && rest.handleEditLayout)
                          rest.handleEditLayout(rest.firebaseId);
                      }}>
                      <HoverTooltip tooltipText="Edit insight">
                        <CandleVIcon />
                      </HoverTooltip>
                    </button>
                  )}
                </FlexRowEnd>
              </FlexRowSpaceBetween>
            )}
            {isLoading ? (
              <FlexRowCenter className="w-full mt-4">
                <ItemLoader />
              </FlexRowCenter>
            ) : (
              <>
                <FlexRow className="gap-2 mb-2 mt-3">
                  <Headline2>{value}</Headline2>
                  <PercentageProgress value={percentProgress} />
                </FlexRow>

                {goal && (
                  <div className="w-full">
                    <MultiLineChart
                      data={goal.activities}
                      completedAt={dayjs(
                        goal?.completedAt || goal?.updatedAt,
                      ).format('MM/DD/YYYY')}
                      lastUpdated={goal.status || ''}
                      KRtargetValue={0}
                      isKpi={goal.isKpi}
                      useCurrentValue={goal.isKpi}
                      dueDate={dayjs(goal.endDate).format('MM/DD/YYYY')}
                      todayDate={dayjs().format('MM/DD/YYYY')}
                      performance={
                        goalDueDate(goal.endDate || '') === 'Past due'
                          ? 'at_risk'
                          : (goal.performance as any)
                      }
                      height={190}
                      margin={{top: 20, right: 5, bottom: 30, left: 40}}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <InsightCard
          config={config}
          overallDateRange={overallDateRange}
          showFlashIcon={Boolean(overallDateRange)}
          isLoading={isLoading}
          showPercentProgress
          value={value}
          percentProgress={percentProgress}
          {...rest}
        />
      )}
    </div>
  );
});
