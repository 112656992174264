import {useCallback, useState} from 'react';
import dayjs from 'dayjs';
import {GoogleCalendarEvent, IMeetingResponse} from '@hooks';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {useNavigate} from 'react-router';
import {useGCalenderHook} from '@hooks/gcalender';

export const useRecurringMeeting = () => {
  const findNextWeekday = useCallback(
    (currentDate: dayjs.Dayjs, weekdays: string[]) => {
      let date = currentDate.clone();
      const weekdayMap: Record<string, number> = {
        MO: 1,
        TU: 2,
        WE: 3,
        TH: 4,
        FR: 5,
        SA: 6,
        SU: 7,
      };

      while (true) {
        date = date.add(1, 'day');
        const currentWeekday = date.day() || 7; // Convert Sunday from 0 to 7
        if (weekdays.some((day) => weekdayMap[day] === currentWeekday)) {
          return date;
        }
      }
    },
    [],
  );

  const findNextMonthDay = useCallback(
    (currentDate: dayjs.Dayjs, monthDay: number) => {
      let date = currentDate.clone();

      // If we've passed this month's occurrence, move to next month
      if (date.date() > monthDay) {
        date = date.add(1, 'month').date(1);
      }

      return date.date(monthDay);
    },
    [],
  );

  const getNextMeetingDateFromGoogleEvent = useCallback(
    (event: GoogleCalendarEvent, currentDate: dayjs.Dayjs) => {
      const recurrence = event.recurrence?.[0];
      if (!recurrence) return currentDate;

      // Parse RRULE from Google Calendar event
      const freq = recurrence.match(/FREQ=([^;]*)/)?.[1];
      const interval = parseInt(
        recurrence.match(/INTERVAL=([^;]*)/)?.[1] || '1',
      );
      const eventStartTime = dayjs(event.start.dateTime || event.start.date);

      switch (freq) {
        case 'WEEKLY': {
          const byDay = recurrence.match(/BYDAY=([^;]*)/)?.[1];
          if (byDay) {
            const weekdays = byDay.split(',');
            const nextDate = findNextWeekday(currentDate, weekdays);
            return nextDate
              .hour(eventStartTime.hour())
              .minute(eventStartTime.minute());
          }
          return currentDate
            .add(interval, 'week')
            .hour(eventStartTime.hour())
            .minute(eventStartTime.minute());
        }
        case 'MONTHLY': {
          const byMonthDay = recurrence.match(/BYMONTHDAY=([^;]*)/)?.[1];
          if (byMonthDay) {
            const monthDay = parseInt(byMonthDay);
            const nextDate = findNextMonthDay(currentDate, monthDay);
            return nextDate
              .hour(eventStartTime.hour())
              .minute(eventStartTime.minute());
          }
          return currentDate
            .add(interval, 'month')
            .hour(eventStartTime.hour())
            .minute(eventStartTime.minute());
        }
        case 'DAILY':
          return currentDate
            .add(interval, 'day')
            .hour(eventStartTime.hour())
            .minute(eventStartTime.minute());
        default:
          return currentDate;
      }
    },
    [findNextMonthDay, findNextWeekday],
  );

  const getDurationFromMeeting = useCallback((meeting: IMeetingResponse) => {
    if (meeting.startDate && meeting.endDate) {
      return dayjs(meeting.endDate).diff(dayjs(meeting.startDate), 'minute');
    }
    return 60; // Default duration
  }, []);

  const {getCalendarEventById} = useGCalenderHook();

  const navigate = useNavigate();

  const [isCreatingNewNote, setIsCreatingNewNote] = useState(false);

  const handleCreateNewNote = async (
    meeting: IMeetingResponse & {
      primaryMeeting?: {title: string; id: string; connectedCalendar: any[]};
      googleEventDetails?: GoogleCalendarEvent;
    },
  ) => {
    const request = new MeetingRequestImpl();
    const controller = new MeetingController(request);

    setIsCreatingNewNote(true);

    try {
      if (!meeting) return;

      const currentDate = dayjs();
      const title = `${meeting.primaryMeeting?.title || meeting.title}`;

      // Base meeting data
      const baseMeetingData = {
        title,
        participant: [],
        type: meeting.type,
        shareAgenda: meeting.shareAgenda,
        shareHostAgenda: meeting.shareHostAgenda,
        shareNote: meeting.shareNote,
        emailParticipant: meeting.emailParticipant || [],
        reminder: meeting.reminder || [],
      };

      // Check if it's a recurring meeting by looking at Google Calendar event data
      const isRecurringMeeting =
        meeting.googleEventDetails?.recurrence ||
        meeting.googleEventDetails?.recurringEventId;

      if (
        isRecurringMeeting &&
        meeting.connectedCalendar &&
        meeting.googleEventDetails
      ) {
        let eventDetails;
        if (meeting.googleEventDetails?.recurrence) {
          eventDetails = meeting.googleEventDetails;
        } else if (meeting.googleEventDetails?.recurringEventId) {
          try {
            const mainEvent = await getCalendarEventById(
              meeting.googleEventDetails?.recurringEventId,
              'primary',
            );

            if (mainEvent?.result) {
              eventDetails = mainEvent.result;
            }
          } catch (error) {}
        }

        // Get the next occurrence based on Google Calendar event data
        const nextMeetingDate = getNextMeetingDateFromGoogleEvent(
          eventDetails,
          currentDate,
        );

        const newMeeting = {
          ...baseMeetingData,
          startDate: nextMeetingDate.format(),
          endDate: nextMeetingDate
            .add(getDurationFromMeeting(meeting), 'minute')
            .format(),
          time: meeting.time,
          calendarId: meeting.calendarId,
        };

        const response = await controller.postMeeting(newMeeting);
        if (response?.id) {
          if (meeting.primaryMeeting?.id) {
            const connectedCalendar = [
              ...meeting.primaryMeeting.connectedCalendar.map((calendar) => ({
                ...calendar,
                _id: undefined,
                id: undefined,
              })),
              {
                calendarId: meeting.calendarId,
                isPrimary: false,
                startDate: nextMeetingDate.format(),
                title,
              },
            ];

            await controller.patchMeeting(
              {
                connectedCalendar,
              },
              meeting.primaryMeeting?.id,
            );

            await controller.patchMeeting(
              {
                connectedCalendar,
              },
              response.id,
            );
          }
          navigate(`/view-meetings/${response.id}`);
        }
      } else {
        // For non-recurring meetings
        const newMeeting = {
          ...baseMeetingData,
          startDate: currentDate.format(),
          endDate: currentDate.add(60, 'minute').format(),
          time: currentDate.format('hh:mm A'),
        };

        const response = await controller.postMeeting(newMeeting);

        if (response?.id) {
          if (meeting.connectedCalendar && meeting.primaryMeeting?.id) {
            const connectedCalendar = [
              ...meeting.primaryMeeting.connectedCalendar.map((calendar) => ({
                ...calendar,
                _id: undefined,
              })),
              {
                meetingId: response.id,
                isPrimary: false,
                startDate: currentDate.format(),
                title,
              },
            ];

            await controller.patchMeeting(
              {
                connectedCalendar,
              },
              meeting.primaryMeeting?.id,
            );

            const primaryMeeting = meeting.connectedCalendar.find(
              (meeting) => meeting.isPrimary,
            );

            if (primaryMeeting)
              await controller.patchMeeting(
                {
                  connectedCalendar: [
                    primaryMeeting,
                    {
                      meetingId: response.id,
                      isPrimary: false,
                      startDate: currentDate.format(),
                      title,
                    },
                  ].map((calendar) => ({...calendar, _id: undefined})),
                },
                response.id,
              );
          }

          navigate(`/view-meetings/${response.id}`);
        }
      }
    } catch (error) {
      console.error('Error creating new note meeting:', error);
    } finally {
      setIsCreatingNewNote(false);
    }
  };

  return {
    getNextMeetingDateFromGoogleEvent,
    findNextWeekday,
    findNextMonthDay,
    getDurationFromMeeting,
    isCreatingNewNote,
    handleCreateNewNote,
  };
};
