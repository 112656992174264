import {useCallback, useMemo} from 'react';
import {useFirebaseFetch} from '@hooks/query-hook';
import {
  writeFirebaseData,
  updateFirebaseData,
  removeFirebaseData,
} from '@utils/firebase-handler';
import {authStore} from '@store/stores/auth-store';
import dayjs from 'dayjs';
import {generateRandomDigits} from '@utils/generate-random-numbers';
import {useWorkspaceUseCase} from '@hooks/workspace-usecase';
import {capitalize} from '@utils';
import {checkinName} from '@utils/checkin-name';

export const useMeetingNotes = (meetingId: string) => {
  const notesPath = `meeting/${meetingId}/notes`;

  const topicsPath = `meeting/${meetingId}/topics`;

  const {data: notes, isLoading: loadingNotes} = useFirebaseFetch(notesPath);
  const {data: topics} = useFirebaseFetch(topicsPath);

  const handleUpdateNotes = useCallback(
    async (
      value: string,
      _id: string,
      field: string,
      shareNote?: boolean,
      prevData?: {
        createdBy: string;
        createdAt: string;
        firebaseId: string;
      },
      topic?: string,
    ) => {
      const firebaseId = prevData?.firebaseId;

      const body = {
        updatedBy: authStore.auth.user.id,
        createdBy: prevData?.createdBy || authStore.auth.user.id,
        createdAt: prevData?.createdAt || dayjs().format(),
        updatedAt: dayjs().format(),
        author: field,
        topic: topic || '',
        note: value,
        shareNote: shareNote || false,
      };

      if (!firebaseId) {
        writeFirebaseData(`meeting/${meetingId}/notes`, body);
        return;
      }

      updateFirebaseData(`meeting/${meetingId}/notes/${firebaseId}`, body);
    },
    [meetingId],
  );

  const handleAddTopic = useCallback(
    async (title: string, isPrivate: boolean) => {
      const body = {
        title,
        isPrivate,
        id: generateRandomDigits(24),
      };

      writeFirebaseData(`meeting/${meetingId}/topics`, body);
    },
    [meetingId],
  );

  const handleEditTopic = useCallback(
    async (key: string, body: any) => {
      updateFirebaseData(`meeting/${meetingId}/topics/${key}`, body);
    },
    [meetingId],
  );

  const handleDeleteTopic = useCallback(
    async (key: string) => {
      removeFirebaseData(`meeting/${meetingId}/topics/${key}`);
    },
    [meetingId],
  );

  // Computed values for notes and topics
  const computedTopics = useMemo(() => {
    if (topics) {
      return Object.entries(topics).map(([key, value]: any) => ({
        ...value,
        key,
      }));
    }
    return [];
  }, [topics]);

  const computedNotes = useMemo(() => {
    if (notes) {
      let hostNote;
      let participantNote;

      for (const value in notes) {
        const note = notes[value];
        if (note.topic) continue;

        if (note.author === 'hostNote') {
          hostNote = {
            ...note,
            firebaseId: value,
            shareHostNote: note.shareNote,
          };
        }
        if (note.author === 'participantNote') {
          participantNote = {
            ...note,
            firebaseId: value,
            shareParticipantNote: note.shareParticipantNote,
          };
        }
      }
      return {hostNote, participantNote};
    }
  }, [notes]);

  const computeTopicNotes = useMemo(() => {
    if (notes) {
      let _groupByNotes: any = {};

      Object.entries(notes).forEach(([key, note]: any) => {
        if (!note.topic) return;

        const groupNotesExists = _groupByNotes?.[note.topic]
          ? _groupByNotes[note.topic]
          : [];

        _groupByNotes[note.topic] = [
          ...groupNotesExists,
          {
            ...note,
            firebaseId: key,
            shareHostNote: note.shareNote,
          },
        ];
      });

      return _groupByNotes;
    }
  }, [notes]);
  const computedTeamNotes = useMemo(() => {
    if (notes) {
      let _notes: any[] = [];

      Object.entries(notes).forEach(([key, note]: any) => {
        if (note.topic) return;

        const groupNotesExists = _notes;

        if (!note.shareNote && note.updatedBy !== authStore.auth.user.id)
          return;

        _notes = [
          ...groupNotesExists,
          {
            ...note,
            firebaseId: key,
            shareHostNote: note.shareNote,
          },
        ];
      });

      return _notes;
    }
  }, [notes]);

  const {
    isPerformanceActive,
    isEngagementActive,
    isCheckinsEnabled,
    isFeedBackEnabled,
  } = useWorkspaceUseCase();

  const managerViewUserOptions = useMemo(() => {
    const options = [];

    if (isPerformanceActive) {
      options.unshift({
        value: 'objective',
        label: 'Goals',
      });
    }

    if (isEngagementActive && isCheckinsEnabled) {
      options.push({
        value: 'check-in',
        label: capitalize(checkinName()),
      });
    }
    if (isEngagementActive && isFeedBackEnabled) {
      options.push({
        value: 'feedback',
        label: 'Feedback',
      });
    }
    options.push({
      value: '1:1',
      label: '1:1s',
    });
    return options;
  }, [
    isPerformanceActive,
    isEngagementActive,
    isFeedBackEnabled,
    isCheckinsEnabled,
  ]);

  return {
    handleUpdateNotes,
    handleAddTopic,
    handleEditTopic,
    handleDeleteTopic,
    computedTopics,
    computedNotes,
    managerViewUserOptions,
    computeTopicNotes,
    computedTeamNotes,
    loadingNotes,
  };
};
