import {activateNotification} from '../../../../../../../ui/molecules/notification/activate-notification';
import {parseErrorContent} from '../../../../../../../utils/error-handling';
import {WorkspaceCheckinsSettingsForm} from './admin-checkins.interface';
import {WorkspaceCheckinsSettingsRequest} from './admin-checkins.request';

export class WorkspaceCheckinsSettingsController {
  constructor(private readonly request: WorkspaceCheckinsSettingsRequest) {}

  async updateCheckinsSettings(
    data: WorkspaceCheckinsSettingsForm,
    removeNotification?: boolean,
    token?: string,
  ) {
    try {
      await this.request.updateCheckinsSettings(data, token);

      if (!removeNotification)
        activateNotification({
          title: 'Success',
          content: `Update settings updated`,
          kind: 'success',
        });

      return true;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: e?.response?.data?.message,
        kind: 'error',
      });
      return false;
    }
  }

  async getCheckinsSettings() {
    try {
      const response = await this.request.getCheckinsSettings();
      return response;
    } catch (e: any) {
      activateNotification({
        title: 'Error',
        content: parseErrorContent(e),
        kind: 'error',
      });
    }
  }
}
