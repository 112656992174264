import {Headline2, Body1} from '@ui/atoms/typography';
import {FlexRowEnd} from '@ui/style/styles';
import {TabNavigation} from '@ui/molecules/tab-nav';
import {useIsRouteActive} from '@utils/route-href';
import {Route, Routes} from 'react-router';
import {QuickInsightPage} from './quick-insight';
import ReportingDashboard from '../reporting/dashboard';
import {useDashboardHook} from '../reporting/dashboard/dashboard-hook';
import {CreateDashboardModal} from '../reporting/dashboard/rename-section';
import {useCallback, useMemo, useState} from 'react';
import {MenuButtonPrimary} from '../you/you-page.styles';
import {useObjectiveInsightHook} from '../reporting/insights/objectives/objective-insight-hook';

const tabRoutes = {
  quickInsight: '',
  reportSnapShot: 'report-snapshot',
};

const InsightDashboard = () => {
  const {isRouteActive} = useIsRouteActive();

  const {dashboards, createDashboard} = useDashboardHook();

  useObjectiveInsightHook();

  const [openModal, toggleModal] = useState(false);

  const handleClose = useCallback(
    (value) => {
      if (value) {
        createDashboard(value).then(() => {
          toggleModal(false);
        });
        return;
      }
      toggleModal(false);
    },
    [createDashboard],
  );

  const handleNavClick = useCallback(() => {}, []);

  const navTabs = useMemo(() => {
    const nav = [
      {
        active: isRouteActive(tabRoutes.quickInsight),
        href: tabRoutes.quickInsight,
        id: '1',
        onClick: handleNavClick,
        icon: <></>,
        iconPosition: '',
        iconSpacing: '',
        title: `Quick Snapshot`,
      },
    ];
    if (dashboards.length) {
      dashboards.forEach((dashboard, index) => {
        nav.push({
          active: isRouteActive(dashboard.key),
          href: dashboard.key,
          id: String(index + 2),
          onClick: handleNavClick,
          icon: <></>,
          iconPosition: '',
          iconSpacing: '',
          title: dashboard.name,
        });
      });
    }
    return nav;
  }, [dashboards, isRouteActive, handleNavClick]);

  return (
    <div>
      <div className="mb-6 flex sm:flex-col flex-row justify-between items-start md:items-center gap-4">
        <div>
          <Headline2>Dashboard</Headline2>
          <div className="w-[80%] mt-1">
            <Body1 kind="textBody">
              Overview of performance, progress on goals and actions, and
              engagement dynamics in real time.
            </Body1>
          </div>
        </div>

        <FlexRowEnd>
          <MenuButtonPrimary onClick={() => toggleModal(true)} width="sm">
            New dashboard
          </MenuButtonPrimary>
        </FlexRowEnd>
      </div>

      <TabNavigation navigations={navTabs} />
      <Routes>
        <Route
          path={`${tabRoutes.quickInsight}/*`}
          element={<QuickInsightPage />}
        />

        {dashboards.map((dashboard) => (
          <Route
            key={dashboard.key}
            path={`${dashboard.key}/*`}
            element={<ReportingDashboard dashboardId={dashboard.key} />}
          />
        ))}
      </Routes>

      <CreateDashboardModal isOpen={openModal} handleClose={handleClose} />
    </div>
  );
};

export default InsightDashboard;
