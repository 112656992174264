import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {MeetingRequestImpl} from '@pages/1:1s/meeting.request';
import {MeetingController} from '@pages/1:1s/meeting-controller';
import dayjs from 'dayjs';
import {authStore} from '@store/stores/auth-store';
import {get} from 'idb-keyval';
import {useGCalenderHook} from '@hooks/gcalender';

export const useGoogleCalendarSync = (
  isSigned: boolean,
  getCalendarEventById: Function,
) => {
  const navigate = useNavigate();

  const {updateEvent} = useGCalenderHook();

  const syncGoogleMeeting = useCallback(
    async (calendarId: string) => {
      if (!isSigned) return;

      try {
        const request = new MeetingRequestImpl();
        const controller = new MeetingController(request);

        // Check for existing meetings
        const existingMeetings = await controller.getMeetings({
          calendarId,
        });

        if (existingMeetings?.results?.length > 0) {
          // If we found an existing meeting, update the URL to use its ID
          const beamMeetingId = existingMeetings.results[0].id;

          navigate(`/view-meetings/${beamMeetingId}`);
          return beamMeetingId;
        }

        // If no existing meeting found, get the calendar event details
        const calendarEvent = await getCalendarEventById(calendarId, 'primary');
        if (!calendarEvent?.result) return;

        const event = calendarEvent.result;

        // Create a new meeting using calendar event details
        const startDateTime = event.start.dateTime || event.start.date;
        const endDateTime = event.end.dateTime || event.end.date;

        const attendees =
          event.attendees?.map((attendee: {email: string}) => attendee.email) ||
          [];

        const users = await get(`${authStore.auth.user.workspace.id}/users`);

        // Find workspace users from attendees
        const participantIds = users
          ?.filter((user: any) => attendees.includes(user.email))
          .map((user: any) => user.id);

        const newMeeting = {
          title: event.summary || 'Untitled Meeting',
          startDate: dayjs(startDateTime).format(),
          time: dayjs(startDateTime).format('hh:mm A'),
          endDate: dayjs(endDateTime).format(),
          participant: participantIds,
          calendarId: calendarId,
          connectedCalendar: [
            {
              isPrimary: true,
              calendarId: calendarId,
              title: event.summary,
              startDate: dayjs(startDateTime).format(),
            },
          ],
        };

        const response = await controller.postMeeting(newMeeting);

        if (response?.id) {
          navigate(`/view-meetings/${response.id}`);
          return response.id;
        }

        return true;
      } catch (error) {
        console.error('Error syncing Google Calendar meeting:', error);
        navigate('/meetings');
        return false;
      }
    },
    [isSigned, getCalendarEventById, navigate],
  );

  const handleToggleBeamAI = useCallback(
    async (
      meetingLink: string,
      isChecked: boolean,
      updateCheck: (value: boolean) => void,
      calendarId: string,
    ) => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      try {
        if (!isChecked) {
          // Turning on BeamAI
          const response = await controller.patchMeeting(
            {
              addBeamAI: true,
              meetingLink,
            },
            calendarId,
          );

          if (response) {
            updateCheck(true);
          }
        } else {
          // Turning off BeamAI
          const response = await controller.patchMeeting(
            {
              addBeamAI: false,
              meetingLink,
            },
            calendarId,
          );

          if (response) {
            updateCheck(false);
          }
        }
      } catch (error) {
        console.error('Error toggling BeamAI:', error);
        updateCheck(isChecked);
      }
    },
    [],
  );

  const handleShareMeeting = useCallback(
    async (
      participants: string[],
      calendarId: string,
      beamMeetingParticipants: string[],
      googleEventAttendees?: any[],
    ) => {
      const request = new MeetingRequestImpl();
      const controller = new MeetingController(request);

      try {
        // Get users from auth store
        const users = await get(`${authStore.auth.user.workspace.id}/users`);

        // Prepare attendees for Google Calendar
        const emails = users
          .filter((user: any) => participants.includes(user.id))
          .map((user: any) => user.email);

        const baseAttendees = emails.map((participant: string) => ({
          email: participant,
          organizer: false,
          self: false,
          responseStatus: 'needsAction',
        }));

        const attendees = googleEventAttendees
          ? [...baseAttendees, ...googleEventAttendees]
          : baseAttendees;

        // Update Google Calendar event if calendar ID exists
        if (calendarId) {
          await updateEvent({attendees}, calendarId, 'primary');
        }

        // Update Beam meeting participants
        const updatedParticipant = beamMeetingParticipants.map(
          (participant: any) =>
            typeof participant === 'string' ? participant : participant?.id,
        );

        const meetingResponse = await controller.patchMeeting(
          {
            participant: [...updatedParticipant, ...participants],
          },
          calendarId,
        );

        return meetingResponse;
      } catch (error) {
        console.error('Error sharing meeting:', error);
        throw error;
      }
    },
    [updateEvent],
  );

  return {
    syncGoogleMeeting,
    handleToggleBeamAI,
    handleShareMeeting,
  };
};
