import {useGCalenderHook} from '@hooks/gcalender';
import {useGoogleCalendarSync} from './hooks/useGoogleCalendarSync';
import {useMeetingActions} from './hooks/useMeetingActions';
import {useMeetingFetch} from './hooks/useMeetingFetch';
import {useRecurringMeeting} from './hooks/useRecurringMeetings';
import {useParams} from 'react-router';
import {useMeetingNotes} from './hooks/useMeetingNotes';
import {useSearchParams} from 'react-router-dom';

export interface PatchMeetingFormData {
  participant: string;
  [x: string]: any;
  shareHostAgenda: boolean;
  hostAgenda: {text: string; completed: boolean}[];
  startDate: string;
  frequency: string;
  time: string;
  reminder: string[];
}

export const useViewMeetingHook = () => {
  const {isSigned, getCalendarEventById, getEmail} = useGCalenderHook();

  const gCalendar = useGoogleCalendarSync(isSigned, getCalendarEventById);

  const {id} = useParams();

  const [searchParams] = useSearchParams();

  const isGoogleMeeting = searchParams.get('meetingType') === 'google';

  const meetingActions = useMeetingActions(id);

  const meetingNotes = useMeetingNotes(id);

  const fetchMeeting = useMeetingFetch(getCalendarEventById, isSigned);

  const recurringMeeting = useRecurringMeeting();

  return {
    ...gCalendar,
    ...meetingActions,
    ...recurringMeeting,
    ...fetchMeeting,
    isSigned,
    ...meetingNotes,
    getEmail,
    isGoogleMeeting,
    id,
  };
};
