import {YouHightLight} from '@pages/dashboard/you/you-components/you-hightligts';
import {Headline3, Body1} from '@ui/atoms/typography';
import {ItemLoader} from '@ui/organisms/item-loader';
import {ProgressDiv} from '../my_checkins-style';
import {observer} from 'mobx-react';
import {VerticalSpacer} from '@ui/atoms/spacer';
import {FlexRowCenter} from '@ui/style/styles';
import {Pagination} from '@ui/molecules/pagination/pagination';
import {useStoreContext} from '@store/store-context';

interface IProgress {
  loadingGoals: boolean;
  isReadOnly: boolean;
  goals: any[];
}
export const ProgressOnObjectives = observer(
  ({loadingGoals, isReadOnly, goals}: IProgress) => {
    const {
      checkinsStore: {
        workspaceCheckinsGoals,
        workspaceGoalsPaginator,
        setWorkspaceGoalsFilterLimit,
        handleWorkspaceGoalsPaginatorNextbtn,
        handleWorkspaceGoalsPaginatorPageClick,
        workspaceGoalsPages,
        handleWorkspaceGoalsPaginatorPrevbtn,
      },
    } = useStoreContext();

    return (
      <>
        {
          <ProgressDiv className="bg-white">
            {(loadingGoals || goals?.length > 0) && (
              <>
                <div className="titleDiv">
                  <Headline3 kind="textDark">
                    Goals
                    <span className="required">*</span> {'  '}
                  </Headline3>
                  <VerticalSpacer size="5px" />
                  <Body1 kind="textBody">Update your progress</Body1>
                </div>
                <div className="contentDiv">
                  {!loadingGoals && goals?.length > 0 && (
                    <YouHightLight
                      goals={
                        new Map(goals?.map((item: any) => [item?.id, item]))
                      }
                      kind="youcheckins"
                      youCheckinsKind={isReadOnly ? 'viewuser' : undefined}
                      isFetching={loadingGoals}
                    />
                  )}
                  {loadingGoals && (
                    <FlexRowCenter className="min-h-[250px]">
                      <ItemLoader />{' '}
                    </FlexRowCenter>
                  )}
                </div>
              </>
            )}

            <FlexRowCenter>
              {workspaceGoalsPaginator.totalResults > 10 ? (
                <Pagination
                  totalPages={workspaceGoalsPaginator.totalPages}
                  page={workspaceGoalsPaginator.page}
                  paginationStyle={{margin: '8px 0px '}}
                  totalResults={workspaceGoalsPaginator.totalResults}
                  maxPageNumberLimit={
                    workspaceGoalsPaginator.maxPageNumberLimit
                  }
                  limit={workspaceGoalsPaginator.limit}
                  updateLimit={setWorkspaceGoalsFilterLimit}
                  showing={workspaceCheckinsGoals.length}
                  minPageNumberLimit={
                    workspaceGoalsPaginator.minPageNumberLimit
                  }
                  goToPage={(value) => {
                    handleWorkspaceGoalsPaginatorPageClick(value);
                  }}
                  pages={workspaceGoalsPages()}
                  nextBtn={handleWorkspaceGoalsPaginatorNextbtn}
                  prevBtn={handleWorkspaceGoalsPaginatorPrevbtn}
                />
              ) : null}
            </FlexRowCenter>
          </ProgressDiv>
        }
        {!loadingGoals && goals?.length === 0 && <></>}
      </>
    );
  },
);
